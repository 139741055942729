import { Component, OnInit,Output, EventEmitter } from '@angular/core';
import { User } from '../../models/user';
import { AgreementService } from '../../services/agreement.service';
import { UserDetailMessageService } from '../../services/message.service';
import { Clipboard } from "@angular/cdk/clipboard"
import { WellnessAndRewardAgreement } from '../../models/api/wellness-and-rewards-agreement';
import { WellnessAndRewardContract } from '../../models/wellness-and-rewards-contract';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs';
import { AGREEMENT, STATUS } from '../../helpers/constants';
import { WellnessAndRewardsHelper1 } from '../../helpers/wellness-and-rewards-helper1';
import { AgreementErrorType } from '../../models/agreement-error-type';

@Component({
  selector: 'app-wellness-and-rewards',
  templateUrl: './wellness-and-rewards.component.html',
  styleUrls: ['./wellness-and-rewards.component.scss']
})
export class WellnessAndRewardsComponent implements OnInit {

  public isLoading = true;
  private user: User;
  public rewardContract:WellnessAndRewardContract;
  @Output() rewardErrorsEventEmitter = new EventEmitter();
  public serviceFailure: boolean = false;

  constructor(private agreementService: AgreementService, private userDetailMessageService: UserDetailMessageService,
    private clipboard: Clipboard) {
    this.user = new User();
    this.rewardContract = WellnessAndRewardContract.EMPTY;
  }

  ngOnInit(): void {
    this.userDetailMessageService.currentMessage.subscribe(message => {
      this.user = (message) ? <User>message : new User();
    });

    this.getWellnessAndRewardAgreements();
  }

  getWellnessAndRewardAgreements() {
    if (this.user) {
      this.isLoading = true;

      this.agreementService.getWellnessAndRewardsAgreement(this.user.uniqueId).pipe(first()).subscribe({
        next: (response: WellnessAndRewardAgreement[]) => {

          let agreements = (<any>response).REWARDS;
          this.rewardContract = WellnessAndRewardsHelper1.getContrats(response);
          this.isLoading = false;
        },
        error: (error: Error) => {
          this.isLoading = false;
          this.serviceFailure = true;
          console.log("Error loading wellness And reward agreement.", error.message)
          let errorType = new AgreementErrorType(error,AGREEMENT.ERROR.AGREEMENTS, AGREEMENT.TYPE.WELLNESS_AND_REWARDS)
          this.rewardErrorsEventEmitter.emit(errorType);
        }
      });
    }
  }

  getStatus(status?:string): boolean {
    if (status == STATUS.ACTIVE.toUpperCase() || !this.serviceFailure )
      return true;
    return false
  }

  public copyPolicyNumber(): void {
    let contractNumber  = this.rewardContract.contractAlpha.concat(this.rewardContract.contractNumber)
    this.clipboard.copy(contractNumber);
  }
}
