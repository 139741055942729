import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { first } from 'rxjs';
import { HealthcareHelper } from '../../helpers/health-care-helper';
import { HealthCareAgreement } from '../../models/api/health-care-agreement';
import { healthcareContract } from '../../models/health-care-contract';
import { User } from '../../models/user';
import { AgreementService } from '../../services/agreement.service';
import { UserDetailMessageService } from '../../services/message.service';
import { Clipboard } from "@angular/cdk/clipboard"
import { AGREEMENT } from '../../helpers/constants';
import { AgreementErrorType } from '../../models/agreement-error-type';

@Component({
  selector: 'app-health-care',
  templateUrl: './health-care.component.html',
  styleUrls: ['./health-care.component.scss']
})
export class HealthCareComponent implements OnInit {

  public isLoading = true;
  private user: User;
  public healthcareContract: healthcareContract;
  @Output() healthErrorsEventEmitter = new EventEmitter();
  public serviceFailure: boolean = false;

  constructor(private agreementService: AgreementService, private userDetailMessageService: UserDetailMessageService,
    private clipboard: Clipboard) {
    this.user = new User();
    this.healthcareContract = healthcareContract.EMPTY;
  }

  ngOnInit(): void {
    this.userDetailMessageService.currentMessage.subscribe(message => {
      this.user = (message) ? <User>message : new User();
    });
    this.userDetailMessageService.setMessage(this.user);

    this.getHealthCareAgreements();

  }

  private getHealthCareAgreements(): void {
    if (this.user) {
      this.isLoading = true;

      this.agreementService.getHealthCareAgreement(this.user.uniqueId).pipe(first()).subscribe({
        next: (response: HealthCareAgreement[]) => {
          console.log('HealthCareAgreement ', response)

          let agreements = (<any>response).HEALTH_CARE;
          this.healthcareContract = HealthcareHelper.getContracts(response);

          console.log('healthcareContract ', this.healthcareContract)
          this.isLoading = false;
        },
        error: (error: Error) => {
          this.isLoading = false;
          this.serviceFailure = true;
          console.log("Error loading health care agreement.", error.message);
          let errorType = new AgreementErrorType(error, AGREEMENT.ERROR.AGREEMENTS, AGREEMENT.TYPE.HEALTH_CARE);
          this.healthErrorsEventEmitter.emit(errorType);
        },
      });
    }
  }

  public getStatus(): boolean {
    if (this.healthcareContract._status.success == true || !this.serviceFailure)
    return true;
  return false;
  }

  copyPolicyNumber() {
    const membershipNumber = this.healthcareContract.membershipNumber
    this.clipboard.copy(membershipNumber);
  }

}
