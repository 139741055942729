import { Amount } from "./amount";
import { Status } from "./api/status";

export class SavingsAndInvestmentContract {

  public origin: string;
  public contractAlpha: string;
  public contractNumber: string;
  public personNumber: number;
  public name: string;
  public status: string;
  public _status: Status;
  public businessUnit: string;
  public inceptionDate: Date;
  public acceptanceDate: Date;
  public investmentValue: Amount;
  public currentValue: Amount;

  constructor(origin: string, contractAlpha: string, contractNumber: string, personNumber: number, name: string, status: string,
    _status: Status, businessUnit: string, inceptionDate: Date, acceptanceDate: Date, investmentValue: Amount,currentValue: Amount) {

    this.origin = origin;
    this.contractAlpha = contractAlpha;
    this.contractNumber = contractNumber;
    this.personNumber = personNumber;
    this.name = name;
    this.status = status;
    this._status = _status;
    this.businessUnit = businessUnit;
    this.inceptionDate = inceptionDate;
    this.acceptanceDate = acceptanceDate;
    this.investmentValue = investmentValue;
    this.currentValue = currentValue;
  }

  public static EMPTY = new SavingsAndInvestmentContract("", "", "", 0, "", "", Status.EMPTY, "", new Date(), new Date(), Amount.EMPTY, Amount.EMPTY);
}
