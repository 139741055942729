<div id="rewards">

  <div class="card-panel card-panel--transitionfadein grid__inner mb-2">
    <div class="grid__cell grid__cell--span-all">
      <div class="mdc-card">
        <div class="mdc-card__content card-panel__container text--align-left">
          <div class="card-panel__heading">
            <h3 class="mdc-card__headline mdc-typography--headline5 text--align-center">
              <span class="text--weight-bold">Momentum Multiply</span>
            </h3>
          </div>

          <div class="card-panel__loader" *ngIf="isLoading">
            <mat-progress-spinner mode="indeterminate" strokeWidth="3" diameter="45">
            </mat-progress-spinner>
          </div>

          <div class="card-panel__body" *ngIf="!isLoading">

            <div class="card-panel__data-container mdc-typography--body2  bb-1-motion-grey--light mb-0 mt-4">
              <div class="card-panel__data-item  grid__inner grid__inner--layout-66-44-22 pt-1 pb-1 bb-1-motion-grey--light">
                <div class="grid__cell mb-0 text--align-left-tablet grid__cell--align-middle">
                  Member number
                </div>

                <div class="grid__cell mb-0 text--align-right grid__cell--align-middle">
                  <span class="mdc-typography--body2 text--weight-bold text--nowrap">
                    {{rewardContract.contractAlpha}}{{rewardContract.contractNumber}}

                    <a *ngIf="rewardContract.contractNumber" href="javascript:void(0)" (click)="copyPolicyNumber()" aria-label="Copy policy number">
                       <mat-icon class="text--dark-medium-emphasis d-valign-middle motion-icon--inline">content_copy</mat-icon>
                     </a>
                  </span>
                </div>
              </div>
            </div>

            <div class="motion-error card-panel__data-container mdc-typography--body2  bb-1-motion-grey--light mb-0">
              <div class="card-panel__data-item  grid__inner grid__inner--layout-66-44-22 pt-2 bb-1-motion-grey--light">
                <div class="grid__cell mb-0 text--align-left-tablet grid__cell--align-middle">
                  Agreements
                </div>

                <div class="grid__cell mb-0 text--align-right grid__cell--align-middle">
                  <span class="mdc-typography--body2" *ngIf="getStatus(rewardContract.status)">
                    <mat-icon class="successStatus">
                      check_circle
                    </mat-icon>
                  </span>

                  <span class="mdc-typography--body2" *ngIf="!getStatus(rewardContract.status)">
                    <mat-icon class="error-label">
                      cancel
                    </mat-icon>
                  </span>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>
  </div>
</div>
