<div class="grid mat-grid">
  <div class="grid__inner grid__inner--layout-c6c">
    <div class="grid__cell bg-white p-4">
      <!-- <img alt="Whoops!" src="{{imageSource}}"> -->
      <h2 class="mdc-typography--headline4">Something went wrong</h2>
    </div>
    <div class="grid__cell">
      <a class="motion-button--cancel" href="/support-portal#/home"
         tabindex="0" title="Return to the home page">
        Close
      </a>
    </div>
  </div>
</div>
