import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ClientIdentificationRequest } from '../models/api/client-identification-request';

export const InterceptorSkipHeader = 'X-Skip-Interceptor';
export const LtpaTokenHeader = 'LtpaToken2';

const skipInterceptorHeaders = new HttpHeaders().set(InterceptorSkipHeader, '');
const ltpaTokenHeaders = new HttpHeaders().set(LtpaTokenHeader, '');

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  protected path: string | undefined;

  constructor(private httpClient: HttpClient) {}

  protected getDomain(): string {
    let domain = environment.domain;
    return domain;
  }

  protected get<TResponse>(endpoint: string): Observable<TResponse> {
    return this.httpClient.get<TResponse>(this.path + endpoint);
  }

  protected getWithParam<TResponse>(endpoint: string, queryParam: ClientIdentificationRequest): Observable<TResponse> {
    let params = new HttpParams();
    params = params.append('queryType', queryParam.queryTypeKey);
    params = params.append('queryValue', queryParam.queryTypeDisplayValue);
    return this.httpClient.get<TResponse>(this.path + endpoint, { params: params });
  }

  protected getWithoutIntercept<TResponse>(endpoint: string): Observable<TResponse> {
    return this.httpClient.get<TResponse>(this.path + endpoint, { headers: skipInterceptorHeaders });
  }

  protected getWithCredentials(endpoint: string): any {
    return this.httpClient.get(this.path + endpoint, { observe: 'response', withCredentials: true });
  }

  protected getWithoutInterceptWithCredentials(endpoint: string): any {
    return this.httpClient.get(this.path + endpoint,
      { headers: skipInterceptorHeaders, observe: 'response', withCredentials: true }
    );
  }

  protected post<TRequest, TResponse>(endpoint: string, request: TRequest): Observable<TResponse> {
    return this.httpClient.post<TResponse>(this.path + endpoint, request);
  }

  protected postWithoutIntercept<TRequest, TResponse>(endpoint: string, request: TRequest): Observable<TResponse> {
    return this.httpClient.post<TResponse>(this.path + endpoint, request, { headers: skipInterceptorHeaders });
  }

  protected put<TRequest, TResponse>(endpoint: string, request: TRequest): Observable<TResponse> {
    return this.httpClient.put<TResponse>(this.path + endpoint, request);
  }

  protected delete<TResponse>(endpoint: string, id: string): Observable<TResponse> {
    return this.httpClient.delete<TResponse>(this.path + endpoint + id);
  }
}
