import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Clipboard } from "@angular/cdk/clipboard"
import { User } from '../../models/user';
import { AgreementService } from '../../services/agreement.service';
import { UserDetailMessageService } from '../../services/message.service';
import { MomentumInsuranceContract } from '../../models/momentum-insurance-contract';
import { MomentumInsuranceAgreement } from '../../models/api/momentum-insurance-agreement';
import { first } from 'rxjs';
import { MomentumInsureAgreementHelper } from '../../helpers/momentum-insure-helper';
import { AGREEMENT, STATUS } from '../../helpers/constants';
import { Status } from '../../models/api/status';
import { AgreementErrorType } from '../../models/agreement-error-type';

@Component({
  selector: 'app-momentum-insurance',
  templateUrl: './momentum-insurance.component.html',
  styleUrls: ['./momentum-insurance.component.scss']
})
export class MomentumInsuranceComponent implements OnInit {

  public isLoading = true;
  private user: User;
  public contracts: MomentumInsuranceContract[];
  @Output() mstiErrorsEventEmitter = new EventEmitter();
  public serviceFailure: boolean = false;


  constructor(private agreementService: AgreementService, private userDetailMessageService: UserDetailMessageService,
    private clipboard: Clipboard) {
    this.user = new User();
    this.contracts = [];
  }

  ngOnInit(): void {
    this.userDetailMessageService.currentMessage.subscribe(message => {
      this.user = (message) ? <User>message : new User();
    });
    this.userDetailMessageService.setMessage(this.user);

    this.getMomentumInsuranceAgreement();
  }

  getMomentumInsuranceAgreement(): void {
    if (this.user) {
      this.isLoading = true;

      this.agreementService.getMomentumInsuranceAgreement(this.user.uniqueId).pipe(first()).subscribe({
        next: (response: MomentumInsuranceAgreement[]) => {

          let agreements = (<any>response).MOMENTUM_INSURE;
          this.contracts = MomentumInsureAgreementHelper.getContracts(response);
          this.isLoading = false;
        },
        error: (error: Error) => {
          this.isLoading = false;
          this.serviceFailure = true;
          this.forceErrorOnContract();
          console.log("Error loading momentum insurance agreement.", error.message)
          let errorType = new AgreementErrorType(error,AGREEMENT.ERROR.AGREEMENTS,AGREEMENT.TYPE.SHORT_TEARM_INSURANCE);
          this.mstiErrorsEventEmitter.emit(errorType);
        }
      });
    }

  }

  copyPolicyNumber(policyNumber: string) {
    this.clipboard.copy(policyNumber);
  }

  public getStatus(status?: string): boolean {
    if (status == STATUS.ACTIVE.toUpperCase() || !this.serviceFailure)
      return true;

    return false
  }

  private forceErrorOnContract() {
    if (this.contracts.length == 0) {
      let contract = MomentumInsuranceContract.EMPTY;
      let status = Status.EMPTY;
      status = new Status(false, 0);
      contract._status = status;
      this.contracts.push(contract);
    }
  }

}
