import { ClipboardModule } from '@angular/cdk/clipboard';
import { TitleCasePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthModule } from 'angular-oauth2-oidc';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {
  ActionComponent,
  AgreementErrorComponent,
  DashboardComponent,
  ErrorComponent,
  ErrorConfirmationDialogComponent,
  FallbackComponent,
  GenericExceptionComponent,
  HeaderComponent,
  HealthCareComponent,
  HomeComponent,
  LifeInsuranceComponent,
  LoaderComponent,
  LogoutComponent,
  MomentumInsuranceComponent,
  NotificationIndicatorComponent,
  OverlayComponent,
  PortfolioComponent,
  ProgressIndicatorComponent,
  ProileSummaryComponent,
  SavingsAndInvestmentComponent,
  SearchUserAccountComponent,
  SupportDetailsComponent,
  UserAccountDetailComponent,
  UserProfileComponent,
  WellnessAndRewardsComponent,
} from './components';
import { MaterialModule } from './modules/material-module';
import { authInterceptorProviders } from './interceptors/auth.interceptor';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    ActionComponent,
    AgreementErrorComponent,
    LoaderComponent,
    ErrorConfirmationDialogComponent,
    HealthCareComponent,
    SavingsAndInvestmentComponent,
    WellnessAndRewardsComponent,
    MomentumInsuranceComponent,
    UserProfileComponent,
    LifeInsuranceComponent,
    NotificationIndicatorComponent,
    SupportDetailsComponent,
    SearchUserAccountComponent,
    UserAccountDetailComponent,
    ErrorComponent,
    ProgressIndicatorComponent,
    DashboardComponent,
    PortfolioComponent,
    ProileSummaryComponent,
    GenericExceptionComponent,
    FallbackComponent,
    OverlayComponent,
    LogoutComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ClipboardModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    OAuthModule.forRoot()
  ],
  providers: [TitleCasePipe,CookieService, authInterceptorProviders],//mockBackendProvider
  bootstrap: [AppComponent]
})
export class AppModule { }
///https://github.com/FullStack-Templates/Angular-template/tree/master/src/app/auth
//https://medium.com/geekculture/authentication-flow-in-angular-93bd79080908
