import { Component } from '@angular/core';

import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc';
import { AuthService } from './services/auth/auth-service.service';
import { authConfig } from './services/auth/auth.config';


let status: boolean = true;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'client-web-support-portal-ts';

  constructor(private authService: AuthService) {
    this.authService.runInitialLoginSequence().then();
  }


}
