import { Component, OnInit } from '@angular/core';
import { SYSTETMS_SUPPORT, TASKS_MANAGEMENT } from 'src/app/helpers/constants';
import { ActionLink } from 'src/app/models/action-link';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit {

  supportPlatforms: ActionLink[] = [];
  tasksmanagement: ActionLink[] =[];

  constructor() { }

  ngOnInit(): void {
    this.supportPlatforms = SYSTETMS_SUPPORT;
    this.tasksmanagement = TASKS_MANAGEMENT;
  }
  linkurl(url: any):void{
    window.open(url, '_blank');
  }
  back():void{

  }

}
