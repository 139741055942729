import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { distinctUntilChanged, first, Subscription } from 'rxjs';
import { ValidateEmailAddress } from '../../directives/email-address-validator.directive';
import { ValidateNumber } from '../../directives/number-validator.directive';
import { ValidateSaId } from '../../directives/sa-id-validator.directive';
import { ValidateIdentificationValue } from '../../directives/validate-identification-value.directive';
import { EMPTY_TEXT } from '../../helpers/constants';
import { ClientIdentificationRequest } from '../../models/api/client-identification-request';
import { ClientIdentification } from '../../models/client-identification';
import { ErrorWithCause } from '../../models/error-with-cause';
import { User } from '../../models/user';
import { ClientIdentificationService } from '../../services/client-identification.service';
import { SupportReferenceMessageService } from '../../services/message.service';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-search-user-account',
  templateUrl: './search-user-account.component.html',
  styleUrls: ['./search-user-account.component.scss']
})
export class SearchUserAccountComponent implements OnInit {

  public isLoading: boolean = true;
  public noProfileFound: boolean = false;
  public userDetailsResult: boolean = false;
  public supportReference: string = EMPTY_TEXT;
  public clientIdentification: ClientIdentification;
  clientIdentificationOptions: ClientIdentificationRequest[];

  users: User[];

  constructor(private router: Router, private clientIdentificationService: ClientIdentificationService,
    private notificationService: NotificationService, private supportReferenceService: SupportReferenceMessageService) {
    this.clientIdentification = new ClientIdentification();
    this.users = [];
    this.clientIdentificationOptions = [];
  }

  ngOnInit(): void {

    this.applyFieldValiations();

    this.isLoading = true;
    this.getClientQueryOptions();
    this.supportReferenceService.currentMessage.subscribe(message => {
      this.supportReference = (message) ? <string>message : EMPTY_TEXT;
    });
    this.supportReferenceService.setMessage(this.supportReference);

  }

  public submit(): void {

    if (this.clientIdentification.isValid()) {
      this.isLoading = true;
      let fieldKey = this.clientIdentification.fieldKey.value.queryTypeKey;
      let fieldValue = this.clientIdentification.fieldValue.value;
      this.clientIdentificationService.getUserDetails(fieldKey, fieldValue).pipe(first()).subscribe({
        next: (response: User[]) => {

          this.users = response;
          this.isLoading = false;
          this.noProfileFound = false;
          this.userDetailsResult = true;
        },
        error: (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.userDetailsResult = false;
          console.log('error.status ', error.status)
          if (error.status == 404) {
            this.noProfileFound = true;
            this.notificationService.notifyInfo('No profile found. Please try again.');
          } else {
            this.notificationService.notifyError('Error retrieved user profile.', error.message);
          }
        }
      })
    }
  }

  private applyFieldValiations(){
    this.clientIdentification.fieldKey.valueChanges.pipe(distinctUntilChanged()).subscribe(value => {
      if (this.clientIdentification.fieldKey.value['queryTypeKey'] == 'EMAILADDRESS')
        this.clientIdentification.fieldValue.setValidators([ValidateEmailAddress]);
      else if (this.clientIdentification.fieldKey.value['queryTypeKey'] == 'IDNUMBER')
        this.clientIdentification.fieldValue.setValidators([ValidateSaId,]);
      else if (this.clientIdentification.fieldKey.value['queryTypeKey'] == 'CLIENTNUMBER')
        this.clientIdentification.fieldValue.setValidators([ValidateNumber,]);
      else
        this.clientIdentification.fieldValue.setValidators([ValidateIdentificationValue,]);
    });
  }

  public back(): void {
    this.supportReferenceService.clear();
    this.router.navigate(['support-details']);
  }

  public clear(): void {
    this.clientIdentification.fieldValue.setValue(null);
  }

  private getClientQueryOptions(): void {
    this.notificationService.notifyInProgress('Loading client search options...', 'CLIENT_SEARCH');
    this.clientIdentificationService.getQueryTypes().pipe(first()).subscribe({
      next: (response: ClientIdentificationRequest[]) => {

        this.clientIdentificationOptions = response;
        this.isLoading = false;
        if (this.clientIdentificationOptions.length > 0)
          this.notificationService.notifySuccess('All client search options retrieved successfully', 'CLIENT_SEARCH');
        else
          this.notificationService.dismissInProgress('CLIENT_SEARCH');

      },
      error: (error: HttpErrorResponse) => {
        this.isLoading = false;
        if (error.status !== 404) {
          console.log("error: ", error);
          this.notificationService.notifyError('Error loading client search options. Please try again.', error.message, 'CLIENT_SEARCH');
        }
      }
    })
  }


}
