import { ClientIdentificationRequest } from "../models/api/client-identification-request";
import { ClientIdentification } from "../models/client-identification";

export class ClientIdentificationTypeMapper {

  public static buildCreateSearchRequest(fieldKey: string, fieldValue: string): ClientIdentificationRequest {

    let request = new ClientIdentificationRequest(fieldKey, fieldValue);
    return request;
  }

}
