import { Component, Input, OnInit } from '@angular/core';
import { AgreementErrorType } from 'src/app/models/agreement-error-type';

@Component({
  selector: 'app-agreement-error',
  templateUrl: './agreement-error.component.html',
  styleUrls: ['./agreement-error.component.scss']
})
export class AgreementErrorComponent implements OnInit {

  @Input() agreementFailed: number = 0;
  @Input() certificateFaild: number = 0;
  @Input() agreementTypeNotFound: boolean = false;
  @Input() errors: AgreementErrorType[] = []

  constructor() { }

  ngOnInit(): void {
  }

}
