import { AgreementTypes } from "../models/agreement-types";
import { AgreementTypeResponse } from "../models/api/agreement-type-response";
import { AGREEMENT } from "./constants";


export class AgreementTypeHelper {

  public static getAgreementType(agreements: AgreementTypeResponse[]): AgreementTypes {
    let agreementType = AgreementTypes.EMPTY;
    agreements.forEach(agreement => {
      switch (agreement.agreementTypeKey) {
        case AGREEMENT.TYPE.HEALTH_CARE:
          agreementType.showHealthCare = true;
          agreementType.agreementTypeNotFound = false;
          break;
        case AGREEMENT.TYPE.LONG_TERM_INSURANCE:
          agreementType.showlifeInsurance = true;
          agreementType.agreementTypeNotFound = false;
          break;
        case AGREEMENT.TYPE.SAVINGS_AND_INVESTMENTS:
          agreementType.showSavingsAndInvestment = true;
          agreementType.agreementTypeNotFound = false;
          break;
        case AGREEMENT.TYPE.MOMENTUM_INSURANCE:
          agreementType.showMomentumInsurance = true;
          agreementType.agreementTypeNotFound = false;
          break;
        case AGREEMENT.TYPE.SHORT_TEARM_INSURANCE:
          agreementType.showShortTermInsurance = true;
          agreementType.agreementTypeNotFound = false;
          break;
        case AGREEMENT.TYPE.WELLNESS_AND_REWARDS:
          agreementType.showWellnesAndRewards = true;
          agreementType.agreementTypeNotFound = false;
          break;
      }
    });

    return agreementType;

  }

}
