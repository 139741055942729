// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'LOCAL',
  domain: 'https://internal-retail-pre.momentum.co.za',
  authO: {
    issuer: 'https://login-dev.momentum.co.za/auth/realms/mmh-staff',
    clientId: '73c7f11a-8461-4334-a34b-167bc8b34554',
    clientSecret: 'uyOowwZA3wPKkKafuYkc9efjc25bgdJO',
    originUri: window.location.origin,
    scope: 'openid profile email',
    response_type: 'code',
    logoutUrl:  window.location.origin,
    showDebugInformation: true,
    timeoutFactor: 0.99
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
