import { Validator } from "../directives/validator";
import { CreateRequest } from "../models/api/create-request";
import { SupportReference } from "../models/support-reference";

export class SupportReferenceHelper {
  public static buildCreateRequest(supportReference: SupportReference):CreateRequest {
    let request = CreateRequest.EMPTY;
    if (Validator.hasValue(supportReference)){
      let referenceNumber = supportReference.supportReferenceNumber.value;

      request = new CreateRequest(referenceNumber);
    }

    return request;
  }

}
