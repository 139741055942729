import { Error } from "./errors";

export class Status {
  private _success: boolean;
  public get success(): boolean {
    return this._success;
  }

  private _statusCodes: number;
  public get statusCode(): number {
    return this._statusCodes;
  }

  private _errors: Error[] = [];
  public get errors(): Error[] {
    return this._errors;
  }

  constructor(success: boolean,statusCode: number,) {
    this._success = success;
    this._statusCodes = statusCode;
  }

  public static EMPTY = new Status(false, 0);
}
