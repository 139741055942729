import { CurrentValue } from "./api/current-value";

export class AdditionalHealthCover {

  public coverName: string;
  public availableBalance: CurrentValue;

  constructor(coverName: string, availableBalance: CurrentValue) {
    this.coverName = coverName;
    this.availableBalance = availableBalance;
  }

  public static EMPTY = new AdditionalHealthCover("", new CurrentValue);

}
