import { CURRENCIES, INTERNATIONAL_MONEY_CODE } from "../helpers/constants";
import { Currency } from "./currency";

export class Amount {

    private _value : number;
    public get value() : number {
        return this._value;
    }

    private _currency : Currency;
    public get currency() : Currency {
        return this._currency;
    }

    constructor(value: number, currency: Currency) {
        this._value = value;
        this._currency = currency;
    }

    //public static EMPTY = new Amount(0, CURRENCIES.get(INTERNATIONAL_MONEY_CODE.ZAR));
    public static EMPTY = new Amount(0, Currency.EMPTY);

    public hasValue(): boolean {
        return (this._value > 0);
    }
}
