
export class AgreementErrorType {
  private _error: Error;
  public get error(): Error {
    return this._error;
  }

  private _type: string;
  public get type(): string {
    return this._type;
  }

  private _agreementType: string;
  public get agreementType(): string {
    return this._agreementType;
  }
  constructor(error: Error, type: string, agreementType: string) {
    this._error = error;
    this._type = type;
    this._agreementType = agreementType
  }
}
