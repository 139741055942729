<div *ngIf="notificationType === notificationTypeEnum.SUCCESS" fxLayout="row" fxLayoutAlign="start center">
  <mat-icon class="notification-icon margin-right-small">check_circle</mat-icon>
  {{message}}
</div>

<div *ngIf="notificationType === notificationTypeEnum.ERROR" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="start center">
      <mat-icon (dblclick)="showTrace = !showTrace" class="notification-icon margin-right-small">cancel</mat-icon>
      <div style="color: white">{{message}}</div>
      <div *ngIf="showTrace && trace.length > 0" style="margin-top: 5px"><p style="word-wrap: break-word;inline-size: 350px">{{trace}}
          <p></div>
  </div>
  <mat-icon (click)="dismiss()" class="notification-dismiss">close</mat-icon>
</div>

<div *ngIf="notificationType === notificationTypeEnum.INFO" fxLayout="row" fxLayoutAlign="start center">
  <mat-icon class="notification-icon margin-right-small">info</mat-icon>
  <div style="inline-size: 350px">{{message}}</div>
</div>

<div *ngIf="notificationType === notificationTypeEnum.WARNING" fxLayout="row" fxLayoutAlign="start center">
  <mat-icon class="notification-icon margin-right-small">error</mat-icon>
  {{message}}
</div>
