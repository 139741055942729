import { EMPTY_TEXT } from "../helpers/constants";

export class User {

  public username: string;
  public emailAddress: string;
  public firstname: string;
  public surname: string;
  public uniqueId: string;
  public clientNumber: string;
  public idNumber: string;
  public passportNumber: string;
  public companyRegistrationNumber: string;
  public gender: string;
  public birthDate: string;
  public cellularNumber: string;
  public failedLoginCount: string;
  public dateLastLogin: string;
  public dateCreated: string;
  public accountStatus: string;
  public roles: string[];

  constructor() {
    this.username = EMPTY_TEXT;
    this.emailAddress = EMPTY_TEXT;
    this.firstname = EMPTY_TEXT;
    this.surname = EMPTY_TEXT;
    this.uniqueId = EMPTY_TEXT;
    this.clientNumber = EMPTY_TEXT;
    this.idNumber = EMPTY_TEXT;
    this.passportNumber = EMPTY_TEXT;
    this.companyRegistrationNumber = EMPTY_TEXT;
    this.gender = EMPTY_TEXT;
    this.birthDate = EMPTY_TEXT;
    this.cellularNumber = EMPTY_TEXT;
    this.failedLoginCount = EMPTY_TEXT;
    this.dateLastLogin = EMPTY_TEXT;
    this.dateCreated = EMPTY_TEXT;
    this.accountStatus = EMPTY_TEXT;
    this.roles = [];
  }


}
