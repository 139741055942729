import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, first, map, retry } from 'rxjs/operators';
import { APPLICATION_NAME, ENDPOINT } from '../helpers/constants';
import { AgreementTypeResponse } from '../models/api/agreement-type-response';
import { ErrorWithCause } from '../models/error-with-cause';
import { HttpService } from './http.service';


@Injectable({
  providedIn: 'root'
})
export class AgreementTypeService extends HttpService {

  private endPointSuffix = '/agreements/types';

  constructor(http: HttpClient) {
    super(http);
    this.initialisePath()
  }

  private initialisePath(): void {
    let domain = this.getDomain();
    this.path = domain + APPLICATION_NAME;
    console.log("current path: " + this.path);
  }

  public getAgreementTypes(guid: string): Observable<AgreementTypeResponse[]> {
    const url: string = ENDPOINT.AGREEMENT + guid + this.endPointSuffix;
    return super.get<AgreementTypeResponse[]>(url).pipe(
      retry(2),
      first(),
      map((agreement: AgreementTypeResponse[]) => {
        return agreement ? agreement : [];
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of([]);
        } else {
          throw error;
        }
      })
    )
  }
}
