import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import {
  DashboardComponent,
  GenericExceptionComponent,
  HomeComponent,
  SearchUserAccountComponent,
  SupportDetailsComponent,
} from './components';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full', },
  { path: 'home', component: HomeComponent, pathMatch: 'full' },
  { path: 'support-details', component: SupportDetailsComponent, canActivate: [AuthGuard],  pathMatch: 'full'},
  { path: 'search-account', component: SearchUserAccountComponent, canActivate: [AuthGuard], pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent,  canActivate: [AuthGuard], pathMatch: 'full' },
  { path: 'exception', component: GenericExceptionComponent, pathMatch: 'full' },
  { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    useHash: false
  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
