import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { AgreementTypeHelper } from '../../helpers/agreement-type-helper';
import { AGREEMENT } from '../../helpers/constants';
import { AgreementErrorType } from '../../models/agreement-error-type';
import { AgreementTypes } from '../../models/agreement-types';
import { AgreementTypeResponse } from '../../models/api/agreement-type-response';
import { User } from '../../models/user';
import { AgreementTypeService } from '../../services/agreement-type.service';
import { UserDetailMessageService } from '../../services/message.service';
import { Utility } from '../../helpers/utility';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {

  agreementFailed: number = 0
  certificateFaild: number = 0;
  isLoading: boolean = false;
  serviceFailure: boolean = false;
  agreementType: AgreementTypes;
  errorTypes: AgreementErrorType[];
  user: User;

  get showErrorTypes() {
    return this.errorTypes.length >= 1 || this.agreementType.agreementTypeNotFound;
  }

  constructor(private router: Router, private agreementTypeService: AgreementTypeService, private userDetailMessageService: UserDetailMessageService) {
    this.user = new User;
    this.agreementType = AgreementTypes.EMPTY;
    this.errorTypes = [];
  }

  ngOnInit(): void {
    this.userDetailMessageService.currentMessage.subscribe(message => {
      this.user = (message) ? <User>message : new User();
    });
    this.userDetailMessageService.setMessage(this.user);

    this.getAgreementTypes();

    console.log("Utility GetDomain",Utility.GetDomain())

    console.log("showErrorTypes -> ",this.showErrorTypes)
  }

  private getAgreementTypes() {
    if (this.user) {
      this.isLoading = true;
      this.agreementTypeService.getAgreementTypes(this.user.uniqueId).pipe(first()).subscribe({
        next: (agreementTypes: AgreementTypeResponse[]) => {
          this.isLoading = false;
          this.agreementType = AgreementTypeHelper.getAgreementType(agreementTypes);
          console.log("  this.agreementTypee", this.agreementType)

        },
        error: (error: Error) => {
          this.agreementType.agreementTypeNotFound = true;
          this.serviceFailure = true;
          this.isLoading = false;
          console.log('Error retriving agreement types ', error)
          this.back();
        }

      });

    } else {
      this.back();
    }
  }

  public agreementErrors(errors: AgreementErrorType) {
    this.errorTypes.push(errors);

    this.agreementFailed = this.errorTypes.filter(error => error.type == AGREEMENT.ERROR.AGREEMENTS).length;
    this.certificateFaild = this.errorTypes.filter(error => error.type == AGREEMENT.ERROR.CERTIFICATES).length;
  }

  public back() {
    this.router.navigate(['search-account']);
  }


}
