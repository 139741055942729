import { Validator } from "../directives/validator";
import { Amount } from "../models/amount";
import { LifeInsuranceAgreement } from "../models/api/life-insurance-agreement";
import { Money } from "../models/api/money";
import { Currency } from "../models/currency";
import { LifeInsuranceContract } from "../models/life-insurance-contract";
import { CURRENCIES } from "./constants";

export class LifeInsuranceAgreementHelper {

  public static getContracts(agreements: LifeInsuranceAgreement[]): LifeInsuranceContract[] {
    let contracts: LifeInsuranceContract[] = [];

    if (Validator.hasItems(agreements)) {

      agreements.forEach(agreement => {
        let contract = this.getContract(agreement);
        contracts.push(contract);
      });
    }
    return contracts;
  }

  private static getContract(agreement: LifeInsuranceAgreement): LifeInsuranceContract {
    let contract = LifeInsuranceContract.EMPTY;

    if (Validator.hasValue(agreement)) {

      let agreementSubtype = agreement.agreementSubtype;
      let origin = agreement.origin.toLocaleLowerCase();
      let contractAlpha = agreement.contractAlpha;
      let contractNumber = agreement.contractNumber;
      let name = agreement.description;
      let packageName = agreement.packageDescription;
      let status = agreement.status;
      let premium = this.getAmount(agreement.premium);
      let premiumFrequency = agreement.premiumFrequency;
      let _status = agreement._status;

      contract = new LifeInsuranceContract(agreementSubtype, origin, contractAlpha, contractNumber, name, packageName, status, premium, premiumFrequency, _status);
    }

    return contract;

  }

  private static getAmount(money: Money): Amount {
    if (Validator.hasValue(money) && Validator.hasValue(money.value) && Validator.hasValue(money.currency))
      return new Amount(money.value, <Currency>CURRENCIES.get(money.currency));

    return Amount.EMPTY;
  }

}
