
export class CreateRequest {

  reference: string = '';

  constructor(referenceNumber: string){
    this.reference = referenceNumber;
  }

  public static EMPTY = new CreateRequest("");
}
