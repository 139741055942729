export class WellnessAndRewardContract {

  public origin: string;
  public contractAlpha: string;
  public contractNumber: string;
  public status: string;
  public points: number;
  public pointsToNextTierStatus: number;
  public pointsToCurrentTierStatus: number;
  public daysToNextTier: Date;
  public dateToNextTierStatus: Date;
  public dateToNextTierStatusReached: boolean;
  public longMessage: string;
  public shortMessage: string;

  constructor(origin: string, contractAlpha: string, contractNumber: string, status: string, points: number, pointsToNextTierStatus: number,
    pointsToCurrentTierStatus: number, daysToNextTier: Date, dateToNextTierStatus: Date, dateToNextTierStatusReached: boolean, longMessage: string, shortMessage: string,) {


    this.origin = origin;
    this.contractAlpha = contractAlpha;
    this.contractNumber = contractNumber;
    this.status = status;
    this.points = points;
    this.pointsToNextTierStatus = pointsToNextTierStatus;
    this.pointsToCurrentTierStatus = pointsToCurrentTierStatus;
    this.daysToNextTier = daysToNextTier;
    this.dateToNextTierStatus = dateToNextTierStatus;
    this.dateToNextTierStatusReached = dateToNextTierStatusReached;
    this.longMessage = longMessage;
    this.shortMessage = shortMessage;
  }

  public static EMPTY = new WellnessAndRewardContract("", "", "", "", 0, 0, 0, new Date(), new Date(), false, "", "");

}
