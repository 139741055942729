import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, map } from 'rxjs';

import { Validator } from '../../directives/validator';
import { authConfig } from './auth.config';
import { environment } from 'src/environments/environment';


// const authConfig: AuthConfig = {

//   // Url of the Identity Provider
//   issuer: 'https://login-dev.momentum.co.za/auth/realms/mmh-staff',
//   //loginUrl:  'https://login-dev.momentum.co.za/auth/realms/mmh-staff/protocol/openid-connect/auth',
//   //logoutUrl:  'https://login-dev.momentum.co.za/auth/realms/mmh-staff/protocol/openid-connect/logout',

//   // URL of the SPA to redirect the user to after login
//   redirectUri: 'http://localhost:4200/',

//   // The SPA's id. The SPA is registered with this id at the auth-server
//   clientId: '73c7f11a-8461-4334-a34b-167bc8b34554',
//   dummyClientSecret: 'uyOowwZA3wPKkKafuYkc9efjc25bgdJO',
//   responseType: 'code',
//   disableAtHashCheck: false,
//   scope: 'openid profile email',
//   useSilentRefresh: false,
//   showDebugInformation: true,
// }




@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  public authenticationEventObservable: Subject<boolean> = new Subject<boolean>();


  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errored, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$]
  ).pipe(map(values => values.every(b => b)));



  constructor(private router: Router, private oauthService: OAuthService) {
    this.oauthService.configure(authConfig);

    this.oauthService.events
    .subscribe(_ => {
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
    });

   this.oauthService.setupAutomaticSilentRefresh();
  }


  public runInitialLoginSequence(): Promise<void> {
  //  return this.oauthService.loadDiscoveryDocumentAndLogin()
    return this.oauthService.loadDiscoveryDocument()
      .then(() => this.oauthService.tryLoginCodeFlow())
      .then(() => {
        this.isDoneLoadingSubject$.next(true);
        // remove query params
        this.router.navigate(['support-details']);
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }

  public runInitialLoginSequence4(): Promise<void> {
    return this.oauthService.loadDiscoveryDocumentAndTryLogin()
    .then(() => {

      this.isDoneLoadingSubject$.next(true);
      if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
        let stateUrl = this.oauthService.state;
        if (!stateUrl.startsWith('/')) {
          stateUrl = decodeURIComponent(stateUrl);
          console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
          this.router.navigateByUrl(stateUrl);
        }
      }


    })  .catch((error) => {
      console.log(error);
      this.isDoneLoadingSubject$.next(true);
    });
  }


  public hasRole(role: string) {
    let claims: any = this.oauthService.getIdentityClaims();
    if (claims && claims.groups) {
      let roles: string[] = claims.groups;
      roles = roles.map(role => role.toUpperCase());
      return roles.includes(role.toLocaleUpperCase());
    }
    return false;
  }

  public getFullname() {
    let claims: any = this.oauthService.getIdentityClaims();
    if (claims && claims.name) {
      return claims.name;
    }
    return undefined;
  }

  public refreshToken() {
    // Refresh token on landing page
    this.refresh();

    // Refresh oAuth token on Interval
    window.setInterval(() => {
      console.log('Refresh token in every 14.5 min');
      this.refresh();
    }, 14.5 * 60 * 1000);
  }


  public login3(){
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.loadDiscoveryDocumentAndLogin().then(_ => {
     // const claims = this.oauthService.getIdentityClaims();
     // this.userName = claims['given_name'];
    //  this.queryApi();
    });
  }


  public login() { this.oauthService.initCodeFlow(); }

  public logout(): void {
    this.oauthService.logOut();
    this.oauthService.revokeTokenAndLogout();
    this.router.navigate(['home']);
  }

  get getLogoutUrl() {
    return window.location.origin +  '/home';
  }


  private refresh() {
    this.oauthService.refreshToken().catch((error) => {
      console.log(error);
    });
  }
  public hasValidToken() { return this.oauthService.hasValidAccessToken(); }


  public isAuthenticated(): boolean {
    if (
      this.oauthService.hasValidAccessToken() &&
      this.oauthService.hasValidIdToken()
    ) {
      return true;
    } else {
      return false;
    }
  }


  get getIdToken(): string {
    return this.oauthService.getIdToken();
  }

  get accessToken(): string {
    return this.oauthService.getAccessToken();
  }

  public hasOauthToken(): boolean {
    let token = this.accessToken;
    return (Validator.hasValue(token));
  }

  public hasToken(): boolean {
    return this.hasOauthToken();
  }


}
