import { AbstractControl } from "@angular/forms";
import { Validator } from "./validator";
import { Error } from "../models/error";

export function ValidateIdentificationValue(control: AbstractControl): { [key: string]: any } | null {
    let value = control.value;

    if (!Validator.hasValue(value))
        return new Error('Please enter ');
    else
        return null;
}
