import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationIndicatorComponent } from '../components/notification-indicator/notification-indicator.component';
import { ProgressIndicatorComponent } from '../components/progress-indicator/progress-indicator.component';
import { NotificationType } from '../models/notification-type';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private inProgress: boolean = false;
  private myMap = new Map<string, boolean>();
  private messageShowDuration: number = 4000;
  private warningShowDuration: number = 5000;

  constructor(public snackBar: MatSnackBar) { }

  isInProgress(taskKey: string = ''): boolean {
    if (taskKey && taskKey.length > 0) {
      if (this.myMap.has(taskKey)) {
        return this.myMap.get(taskKey) as boolean;
      }
    } else {
      return this.inProgress;
    }
    return this.inProgress;
  }

  /*These 4 states are used for typical back end calls and feedback from observables, isInProgress can be queried to check if the state is still in progress*/
  /*Multiple tasks can run at the same time and can be tracked via the taskKey*/

  /*-----------------------------------------------------------------------------------*/
  notifyInProgress(message: string, taskKey: string = '') {
    if (taskKey && taskKey.length > 0) {
      this.myMap.set(taskKey, true);
    } else {
      this.inProgress = true;
    }

    this.snackBar.openFromComponent(ProgressIndicatorComponent, {
      data: message,
      panelClass: ['snackbar-progress']
    });
  }

  notifyInProgressWithoutIndicator(message: string, taskKey: string = '') {
    if (taskKey && taskKey.length > 0) {
      this.myMap.set(taskKey, true);
    } else {
      this.inProgress = true;
    }

    this.snackBar.openFromComponent(NotificationIndicatorComponent, {
      data: { message: message, notificationType: NotificationType.INFO },
      panelClass: ['snackbar-info']
    });
  }

  notifySuccess(message: string, taskKey: string = '') {
    if (taskKey && taskKey.length > 0) {
      if (this.myMap.has(taskKey)) {
        this.myMap.set(taskKey, false);
      }
    } else {
      this.inProgress = false;
    }

    this.snackBar.openFromComponent(NotificationIndicatorComponent, {
      data: { message: message, notificationType: NotificationType.SUCCESS },
      duration: this.messageShowDuration,
      panelClass: ['snackbar-positive']
    });
  }

  notifyError(message: string, trace: string = '', taskKey: string = '') {
    if (taskKey && taskKey.length > 0) {
      if (this.myMap.has(taskKey)) {
        this.myMap.set(taskKey, false);
      }
    } else {
      this.inProgress = false;
    }

    this.snackBar.openFromComponent(NotificationIndicatorComponent, {
      data: { message: message, notificationType: NotificationType.ERROR, trace: trace },
      panelClass: ['snackbar-error']
    });
  }

  dismissInProgress(taskKey: string = '') {
    if (taskKey && taskKey.length > 0) {
      if (this.myMap.has(taskKey)) {
        this.myMap.set(taskKey, false);
        this.inProgress = false;
        this.snackBar.dismiss();
      }
    } else {
      this.inProgress = false;
      this.snackBar.dismiss();
    }
  }

  /*-----------------------------------------------------------------------------------*/

  notifyInfo(message: string, duration: number = 0) {
    this.snackBar.openFromComponent(NotificationIndicatorComponent, {
      data: { message: message, notificationType: NotificationType.INFO },
      duration: duration > 0 ? duration : this.messageShowDuration,
      panelClass: ['snackbar-info']
    });
  }

  notifyWarning(message: string) {
    this.snackBar.openFromComponent(NotificationIndicatorComponent, {
      data: { message: message, notificationType: NotificationType.WARNING },
      duration: this.warningShowDuration,
      panelClass: ['snackbar-caution']
    });
  }
}
