import { FormControl } from "@angular/forms";
import { ValidateSupportReferenceNumber } from "../directives/validate-support-reference-number.directive";

export class SupportReference {
  supportReferenceNumber: FormControl

  constructor() {
    this.supportReferenceNumber = new FormControl('', ValidateSupportReferenceNumber);
  }

  public isValid(): boolean {
    this.supportReferenceNumber.updateValueAndValidity();

    return this.supportReferenceNumber.valid;
  }

  public setValues(referenceNumber: string) {
    this.supportReferenceNumber.setValue(referenceNumber);
  }
}
