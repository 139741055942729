import { Validator } from "../directives/validator";
import { Amount } from "../models/amount";
import { MomentumInsuranceAgreement } from "../models/api/momentum-insurance-agreement";
import { Money } from "../models/api/money";
import { Currency } from "../models/currency";
import { MomentumInsuranceContract } from "../models/momentum-insurance-contract";
import { CURRENCIES } from "./constants";

export class MomentumInsureAgreementHelper {

  public static getContracts(agreements: MomentumInsuranceAgreement[]): MomentumInsuranceContract[] {
    let contracts: MomentumInsuranceContract[] = [];

    if (Validator.hasItems(agreements)) {

      agreements.forEach(agreement => {
        let contract = this.getContract(agreement);
        contracts.push(contract);
      });
    }
    return contracts;
  }

  private static getContract(agreement: MomentumInsuranceAgreement): MomentumInsuranceContract {
    let contract = MomentumInsuranceContract.EMPTY;

    if (Validator.hasValue(agreement)) {

      let agreementSubtype = agreement.agreementSubtype;
      let origin = agreement.origin;
      let contractAlpha = agreement.contractAlpha;
      let contractNumber = agreement.contractNumber;
      let name = agreement.description;
      let status = agreement.status;
      let premium = this.getAmount(agreement.premium);;
      let premiumFrequency = agreement.premiumFrequency;
      let _status = agreement._status;
      let code = agreement.productCode;

      contract = new MomentumInsuranceContract(agreementSubtype, origin, contractAlpha, contractNumber, name, status, premium, premiumFrequency, _status, code)
    }

    return contract;

  }

  private static getAmount(money: Money): Amount {
    if (Validator.hasValue(money) && Validator.hasValue(money.value) && Validator.hasValue(money.currency))
      return new Amount(money.value, <Currency>CURRENCIES.get(money.currency));

    return Amount.EMPTY;
  }

}
