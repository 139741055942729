import { Amount } from "./amount";
import { Status } from "./api/status";

export class LifeInsuranceContract {

  public agreementSubtype: string;
  public origin: string;
  public contractAlpha: string;
  public contractNumber: string;
  public name: string;
  public packageName: string;
  public status: string;
  public _status: Status;
  public premium: Amount;
  public premiumFrequency: string;

  constructor(agreementSubtype: string, origin: string, contractAlpha: string, contractNumber: string, name: string,packageName:string, status: string, premium: Amount, premiumFrequency: string, _status: Status) {

    this.agreementSubtype = agreementSubtype;
    this.origin = origin;
    this.contractAlpha = contractAlpha;
    this.contractNumber = contractNumber;
    this.name = name;
    this.packageName = packageName;
    this.status = status;
    this._status = _status;
    this.premium = premium;
    this.premiumFrequency = premiumFrequency;
  }

  public static EMPTY = new LifeInsuranceContract("", "", "", "","", "", "", Amount.EMPTY, "", Status.EMPTY);
}
