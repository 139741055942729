import { AbstractControl } from "@angular/forms";
import { Validator } from './validator';
import { SaIdValidator } from './sa-id-validator';
import { Error } from '../models/error';

export function ValidateSaId(control: AbstractControl): { [key: string]: any } | null {
    let value = control.value;

    if (!Validator.hasValue(value))
        return new Error('Please enter your South African identification number.');
    else if (!SaIdValidator.hasValidDate(value) || !SaIdValidator.hasValidChecksum(value))
        return new Error('Please enter a valid South African identification number.');
    else
        return null;
}