export class ClientIdentificationRequest {

  private _queryTypeKey: string;
  public get queryTypeKey() {
    return this._queryTypeKey;
  }

  private _queryTypeDisplayValue: string
  public get queryTypeDisplayValue() {
    return this._queryTypeDisplayValue;
  }

  constructor(queryTypeKey: string, queryTypeDisplayValue: string) {
    this._queryTypeKey = queryTypeKey;
    this._queryTypeDisplayValue = queryTypeDisplayValue;
  }

}
