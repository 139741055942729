import { AbstractControl } from "@angular/forms";
import { Error } from "../models/error";
import { Validator } from "./validator";


export function ValidateIdentificationType(control: AbstractControl): { [key: string]: any } | null {
  let value = control.value;

  if (!Validator.hasValue(value))
    return new Error('Please select search option.');
  else
    return null;
}
