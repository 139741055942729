import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NotificationType } from '../../models/notification-type';

@Component({
  selector: 'app-notification-indicator',
  templateUrl: './notification-indicator.component.html',
  styleUrls: ['./notification-indicator.component.scss']
})
export class NotificationIndicatorComponent {

  notificationType: NotificationType;
  message: string;
  trace: string;
  showTrace: boolean = false;

  // Expose enum to html template
  public get notificationTypeEnum(): typeof NotificationType {
    return NotificationType;
  }

  constructor(public snackBarRef: MatSnackBarRef<NotificationIndicatorComponent>, @Inject(MAT_SNACK_BAR_DATA) public data: { message: string, notificationType: NotificationType, trace: string }) {
    this.notificationType = data.notificationType;
    this.message = data.message;
    this.trace = data.trace;
  }

  dismiss() {
    this.snackBarRef.dismiss();
  }

}
