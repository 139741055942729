<div id="actionDetails">

  <div class="grid__cell--span-all mdc-typography--caption text--align-left text--weight-light mb-1 mt-4">
    LINKS
  </div>

  <div class="grid__inner grid__inner--layout-12">

    <div class="grid__cell demo-bloc">
      <div class="actions-panel grid__inner grid__inner--layout-12 bg-white p-4">
        <div class="actions-panel__links-container grid__cell">
          <div class="card-panel__heading text--align-left">
            <h5 class="mdc-card__headline mdc-typography--headline6 mb-0">
              Support Platform
            </h5>
          </div>

          <ng-container *ngFor="let item of supportPlatforms">
            <div class="mdc-typography--body2">
              <div class="text--align-left">
                <div>
                  <a class="text--weight-medium" href="javascript:void(0)" title="{{item.name}}"
                    (click)="linkurl(item.link)">
                    <mat-icon class="d-valign-middle motion-icon--inline pr-1">open_in_new</mat-icon><span>{{item.name}}</span>
                  </a>
                </div>
              </div>
            </div>
          </ng-container>

        </div>
      </div>
    </div>

    <div class="grid__cell demo-bloc">
      <div class="actions-panel grid__inner grid__inner--layout-12 bg-white p-4">
        <div class="actions-panel__links-container grid__cell">
          <div class="card-panel__heading text--align-left">
            <h5 class="mdc-card__headline mdc-typography--headline6 mb-0">
              Task management
            </h5>
          </div>

          <ng-container *ngFor="let item of tasksmanagement">
            <div class="mdc-typography--body2">
              <div class="text--align-left">
                <a class="text--weight-medium" href="javascript:void(0)" title="{{item.name}}"
                  (click)="linkurl(item.link)">
                  <mat-icon class="d-valign-middle motion-icon--inline pr-1">open_in_new</mat-icon><span>{{item.name}}</span>
                </a>
              </div>
            </div>
          </ng-container>

        </div>
      </div>
    </div>

  </div>

</div>
