<div class="mdc-card__content text--align-left">
  <div class="card-panel__heading" *ngIf="user">
    <h3 class="mdc-card__headline mdc-typography--headline5 text--align-center text--weight-bold">
      {{ user.firstname | titlecase }} {{ user.surname | titlecase }}
      <span class="text--weight-light"></span>
    </h3>
  </div>
  <div class="card-panel__body">
    <div class="card-panel__data-container mdc-typography--body2  mb-2">
      <div class="card-panel__data-item  grid__inner grid__inner--layout-66-44-22 pt-2 pb-2">
        <div class=" grid__cell mb-0 text--align-left-tablet grid__cell--align-middle">
          Username
        </div>
        <div class="grid__cell mb-0 text--align-right-tablet text--align-right text--weight-bold text--breakword">
          {{ user.username }}
        </div>
      </div>

      <div *ngIf="user.emailAddress" class="card-panel__data-item  grid__inner grid__inner--layout-66-44-22 pt-2 pb-2">
        <div class=" grid__cell mb-0 text--align-left-tablet grid__cell--align-middle">
          Email address
        </div>
        <div class="grid__cell mb-0 text--align-right-tablet text--align-right text--weight-bold text--breakword">
          {{ user.emailAddress }}
        </div>
      </div>

      <div *ngIf="user.idNumber" class="card-panel__data-item  grid__inner grid__inner--layout-66-44-22 pt-2 pb-2">
        <div class=" grid__cell mb-0 text--align-left-tablet grid__cell--align-middle">
          South African ID number
        </div>
        <div class="grid__cell mb-0 text--align-right-tablet text--align-right text--weight-bold">
          {{ user.idNumber }}
        </div>
      </div>

      <div *ngIf="user.passportNumber"
        class="card-panel__data-item  grid__inner grid__inner--layout-66-44-22 pt-2 pb-2">
        <div class=" grid__cell mb-0 text--align-left-tablet grid__cell--align-middle">
          Passport number
        </div>
        <div class="grid__cell mb-0 text--align-right-tablet text--align-right text--weight-bold">
          {{ user.passportNumber }}
        </div>
      </div>

      <div *ngIf="user.companyRegistrationNumber"
        class="card-panel__data-item  grid__inner grid__inner--layout-66-44-22 pt-2 pb-2">
        <div class=" grid__cell mb-0 text--align-left-tablet grid__cell--align-middle">
          Registration number
        </div>
        <div class="grid__cell mb-0 text--align-right-tablet text--align-right text--weight-bold">
          {{ user.companyRegistrationNumber }}
        </div>
      </div>

      <div *ngIf="user.clientNumber" class="card-panel__data-item  grid__inner grid__inner--layout-66-44-22 pt-2 pb-2">
        <div class=" grid__cell mb-0 text--align-left-tablet grid__cell--align-middle">
          Client number
        </div>
        <div class="grid__cell mb-0 text--align-right-tablet text--align-right text--weight-bold">
          {{ user.clientNumber}}
        </div>
      </div>

      <div *ngIf="user.dateLastLogin" class="card-panel__data-item  grid__inner grid__inner--layout-66-44-22 pt-2 pb-2">
        <div class=" grid__cell mb-0 text--align-left-tablet grid__cell--align-middle">
          Last login
        </div>
        <div class="grid__cell mb-0 text--align-right-tablet text--align-right text--weight-bold">
          {{ user.dateLastLogin | date: 'dd MMMM yyyy'}}
        </div>
      </div>
    </div>
  </div>
  <div class="mdc-btn-container" *ngIf="hideViewButton">
    <a href="javascript:void(0)" class="mdc-button motion-button--medium mdc-ripple-upgraded" color="accent"
      title="View" (click)="view()">View</a>
  </div>

</div>
