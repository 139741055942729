import { ActionLink } from '../models/action-link';
import { Currency } from '../models/currency';
import { ProductType } from '../models/product-type';

export const APPLICATION_NAME = '/client-web-support-portal-api/';
export const EMPTY_TEXT = '';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const USER_SESSION = 'USER_SESSION';
export const USER_GUID = 'USER_GUID';
export const DOMAIN = '.momentum.co.za';
export const COOKIE_PATH = '/';
export const COOKIE_EXPIRE = 0.01;
export const USER_ROLES = 'USER_ROLES';
export const CLIENT_USER_ROLES = 'CLIENT_USER_ROLES';
export const CLIENT_CIF_TOKEN = 'CLIENT_CIF_TOKEN';
export const  CIF_TOKEN = 'CIF_TOKEN';
export const DEFAULT_LOGOUT_PATH = '/support-portal#/home';
export const BASE_IMAGE_PATH = '/images/svg/';
export const SOMETHING_WRONG_IMG_PATH = BASE_IMAGE_PATH + 'shoe-gum.svg';

export const COOKIE = {
  KEY: {
      CIF_TOKEN: "CIF_TOKEN",
      USER_ROLES: "USER_ROLES"
  },
  EXPIRATION_TIME: 0.01,
  DOMAIN: ".momentum.co.za",
  PATH: "/"
};

export const DEFAULT = {
  SAVE_PERIOD: 3,
  LARGE_ANSWER_CHARACTERS_AMOUNT: 31,
  COUNTRY_CODE: "ZAF",
  OFFSET_VALUE: -280
}

export const ENDPOINT = {
  AGREEMENT: 'accounts/',
  SEARCH_QUERY: 'accounts',
  QUERY_TYPES: 'accounts/query-types',
  SUPPORT_REFERENCE: 'support/references'

};

export const AGGREMENT_TYPE = {
  HEALTH_AND_CARE: '/agreements/health.care',
  LIFE_INSURANCE: '/agreements/long.term.insurance',
  MOMENTUME_INSURANCE: '/agreements/momentum.insurance',
  SAVINGS_AND_INVESTMENTS: '/agreements/savings.and.investments',
  SHORT_TERM_INSURANCE: '/agreements/short.term.insurance',
  WELLNESS_AND_REWARDS: '/agreements/wellness.and.rewards',
};

export const INTERNATIONAL_MONEY_CODE = {
  ZAR: "ZAR",
  USA: "USD",
  GBP: "GBP",
  EUR: "EUR"
};

export const CURRENCIES: Map<string, Currency> = new Map([
  [INTERNATIONAL_MONEY_CODE.ZAR, new Currency(INTERNATIONAL_MONEY_CODE.ZAR, "R")],
  [INTERNATIONAL_MONEY_CODE.USA, new Currency(INTERNATIONAL_MONEY_CODE.USA, "$")],
  [INTERNATIONAL_MONEY_CODE.GBP, new Currency(INTERNATIONAL_MONEY_CODE.ZAR, "£")],
  [INTERNATIONAL_MONEY_CODE.EUR, new Currency(INTERNATIONAL_MONEY_CODE.USA, "€")],
]);


export const SYSTETMS_SUPPORT: ActionLink[] = [
  {
    name: 'User manager',
    link: 'https://internal-retail.momentum.co.za/UserManagement/contents/findUser.do'
  },
  {
    name: 'LDAP',
    link: 'https://internal-retail.momentum.co.za/UserManagement/contents/findUser.do,'
  },
  {
    name: 'CDI',
    link: 'https://sswebprd.mmiholdings.co.za/CDIAdministration/'
  },
];

export const TASKS_MANAGEMENT: ActionLink[] = [
  {
    name: 'Fresh desk',
    link: 'https://momretail.freshdesk.com/a/dashboard/default'
  },
  {
    name: 'Jira',
    link: 'https://inside.momentummetropolitan.co.za/jira/secure/insight/search?schema=1'
  },
  {
    name: 'Katana',
    link: 'http://katana.momentum.co.za/secure/Dashboard.jspa'
  },
];

export const STATUS = {
  ACTIVE : 'Active',
  INACTIVE : 'Imactive',
  LOCKED : 'Locked'
}

export const HEALTH_AGREEMENT = {
  MEDICAL_AID: 'medical.aid',
  HEALTH_SAVER: 'health.saver',
  GAP_COVER: 'gapcover',
  HEALTH_4_Me: 'health4me'
}

export const PRODUCT = {
  TYPE: {
    FUNDS_AT_WORK: 'FundAtWork',
    INVESTMENTS: 'Investments',
    MYRIAD: 'Myriad',
    RETIREMENT_INCOME: 'Retirement income',
    RETIREMENT_SAVINGS: 'Retirement savings',
    TAX_FREE_SAVINGS: 'Tax-free savings',
    NONE: 'Additional products',
  }
};

export const AGREEMENT = {
  TYPE: {
    FUNDS_AT_WORK: 'funds.at.work',
    HEALTH_CARE: 'health.care',
    LONG_TERM_INSURANCE: 'long.term.insurance',
    MOMENTUM_INSURANCE: 'momentum.insurance',
    MYRIAD: 'myriad',
    SAVINGS_AND_INVESTMENTS: 'savings.and.investments',
    SHORT_TEARM_INSURANCE: 'short.term.insurance',
    WELLNESS_AND_REWARDS: 'wellness.and.rewards'
  },
  ORIGIN: {
    INVESTO: 'INVESTO',
    WEALTH: 'WEALTH',
    METCI: 'METCI',
    PDS: 'PDS',
    EMPLOYEE_BENEFITS: 'EMPLOYEE_BENEFITS',
    MYRIAD: 'MYRIAD',
    MULTIPLY: "MULTIPLY",
    FUNDSATWORKSDESC: 'FundsAtWork',
  },
  ERROR: {
    AGREEMENTS: 'Agreements',
    CERTIFICATES: 'Certificate'
  }
};

export const PRODUCT_DETAILS: Map<string, ProductType[]> = new Map([
  [
    AGREEMENT.ORIGIN.EMPLOYEE_BENEFITS, [
      new ProductType(PRODUCT.TYPE.RETIREMENT_SAVINGS, [])
    ]
  ],
  [
    AGREEMENT.ORIGIN.METCI, [
      new ProductType(PRODUCT.TYPE.INVESTMENTS, [])
    ]
  ],
  [
    AGREEMENT.ORIGIN.PDS, [
      new ProductType(PRODUCT.TYPE.NONE, [])
    ]
  ],
  [
    AGREEMENT.ORIGIN.INVESTO, [
      new ProductType(PRODUCT.TYPE.INVESTMENTS, ['SU', 'UT', 'LS']),
      new ProductType(PRODUCT.TYPE.RETIREMENT_INCOME, ['LA']),
      new ProductType(PRODUCT.TYPE.RETIREMENT_SAVINGS, ['UA']),
      new ProductType(PRODUCT.TYPE.NONE, []),
    ]
  ],
  [
    AGREEMENT.ORIGIN.WEALTH, [
      new ProductType(PRODUCT.TYPE.INVESTMENTS, ['Flexible Investment Option', 'Offshore Investment Option',
        'Savings (Dream, Education, Retirement) Investment', 'Trust Flexible Investment Option', 'Flexible Endowment Option',
        'Traded Endowment Option', 'Savings (Dream, Education, Retirement) Endowment', 'Offshore Endowment Option',
        'Trust Flexible Endowment Option', 'MCI Unit Trust', 'Guaranteed Return Option', 'VOLUNTARY TERM CERTAIN ANNUITY', 'VOLUNTARY LIFE ANNUITY']),
      new ProductType(PRODUCT.TYPE.RETIREMENT_INCOME, ['Retirement Income Option']),
      new ProductType(PRODUCT.TYPE.RETIREMENT_SAVINGS, ['Retirement Annuity Option', 'Savings Retirement Annuity',
        'Retirement Preservation Option']),
      new ProductType(PRODUCT.TYPE.TAX_FREE_SAVINGS, ['Flexible Tax-free Option',
        'Tax Free (Dream, Education, Retirement) Savings']),
    ]
  ],
  [
    AGREEMENT.ORIGIN.MYRIAD, [
      new ProductType(PRODUCT.TYPE.MYRIAD, ['SU', 'UT', 'LS']),
      new ProductType(PRODUCT.TYPE.FUNDS_AT_WORK, ['LA']),
      new ProductType(PRODUCT.TYPE.NONE, []),
    ]
  ],
]);
