<nav class="navbar">
  <!-- LOGO -->
  <div class="logo">Support Portal</div>

  <!-- NAVIGATION MENU -->
  <ul class="nav-links">
    <!-- NAVIGATION MENUS -->
    <div class="menu">
      <li class="nav-item" *ngIf="!isAuthenticated">
        <a class='nav-link no-caret' style="cursor:pointer" (click)="login()">
          <span class="nav-link-inner--text">Login</span>
        </a>
      </li>
      <li class="nav-item" *ngIf="isAuthenticated">
        <a class='nav-link no-caret' style="cursor:pointer" (click)="logout()">
          <span class="nav-link-inner--text">Logout</span>
        </a>
      </li>
    </div>
  </ul>
</nav>

