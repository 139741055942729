import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY_TEXT } from '../../helpers/constants';
import { User } from '../../models/user';
import { AgreementService } from '../../services/agreement.service';
import { SupportReferenceMessageService, UserDetailMessageService } from '../../services/message.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public isLoading: boolean = false;
  public user: User;
  public supportReference: string = EMPTY_TEXT;


  constructor(private router: Router, private userDetailMessageService: UserDetailMessageService,
    private supportReferenceService: SupportReferenceMessageService,private agreementService: AgreementService,) {
    this.user = new User();

  }

  ngOnInit(): void {
    this.userDetailMessageService.currentMessage.subscribe(message => {
      this.user = (message) ? <User>message : new User();
    });

    this.supportReferenceService.currentMessage.subscribe(message => {
      this.supportReference = (message) ? <string>message : EMPTY_TEXT;
    });
    this.supportReferenceService.setMessage(this.supportReference);

  }
  public back() {
    this.userDetailMessageService.clear();
    this.router.navigate(['search-account']);
  }

}
