import { Component, OnInit } from '@angular/core';
import { SOMETHING_WRONG_IMG_PATH } from 'src/app/helpers/constants';

@Component({
  selector: 'app-generic-exception',
  templateUrl: './generic-exception.component.html',
  styleUrls: ['./generic-exception.component.scss']
})
export class GenericExceptionComponent implements OnInit {
  imageSource !: string;
  constructor() { }

  ngOnInit(): void {
    this.imageSource =  "";
  }

}
