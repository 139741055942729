<div class="grid mt-4">

  <div class=" slidein-up p-2 bg-primary-blue--light bb-2-momentum-blue">
    <div id="motion-heading-container" class="d-table ">
      <div class="grid__inner grid__inner--layout-66-44-22">
        <div  class="grid__cell 360-heading  mdc-typography--body1 text--align-left text--align-right-tablet">
          <div class="mb-0 d-inline-flex d-valign-middle"> <i class="material-icons text--dark-medium-emphasis pr-1" tabindex="0">event_note</i> Support reference:<span class="pl-1 text--weight-semibold">{{this.supportReference}}</span></div>
        </div>
        <div class="grid__cell mdc-typography--body1 text--align-left">
          <div  class="mb-0 d-inline-flex d-valign-middle"> <i class="material-icons text--dark-medium-emphasis pr-1" tabindex="0">people_alt</i>Selected profile:<span class="pl-1 text--weight-semibold">{{this.user.username}}</span></div>
        </div>
      </div>
    </div>
  </div>

  <div class="grid__inner grid__inner--layout-363 mdc-theme--background">
    <div class="grid__col demo-bloc-col col-panel active col-panel--transitionfadein slidein-left"
      data-meta-tab="tab1" for="#mdc-360-tabs">

      <span>
        <app-profile-summary [userProfile]="user"></app-profile-summary>
      </span>
    </div>

    <div class="grid__col demo-bloc-col col-panel slidein-up" for="#mdc-360-tabs" data-meta-tab="tab2">
      <span *ngIf="!isLoading">
        <app-portfolio></app-portfolio>
      </span>

      <span *ngIf="isLoading">
        <div class="grid__cell--span-all mdc-typography--caption text--align-left text--weight-light mb-1 mt-3">
          PORTFOLIO
        </div>

        <div class="card-panel card-panel--transitionfadein grid__inner mb-2">
          <div class="grid__cell grid__cell--span-all">
            <div class="mdc-card">
              <div class="mdc-card__content card-panel__container">
                <mat-progress-spinner mode="indeterminate" strokeWidth="3" diameter="45">
                </mat-progress-spinner>
              </div>
            </div>
          </div>
        </div>
      </span>

    </div>

    <div class="colPanel grid__col demo-bloc-col slidein-right" for="#mdc-360-tabs" data-meta-tab="tab3">
      <span *ngIf="!isLoading">
        <app-action></app-action>
      </span>

      <span *ngIf="isLoading">
        <div class="grid__cell--span-all mdc-typography--caption text--align-left text--weight-light mb-1 mt-3">
          <span>ADVICE</span>
        </div>
        <div class="card-panel grid__inner mb-2 card-panel--transitionfadein">
          <div class="grid__cell grid__cell--span-all">
            <div class="mdc-card">
              <div class="mdc-card__content text--align-left">
                <mat-progress-spinner mode="indeterminate" strokeWidth="3" diameter="45">
                </mat-progress-spinner>
              </div>
            </div>
          </div>
        </div>
      </span>
    </div>

  </div>

</div>

<div class="grid__cell text--align-center">
  <span>
      <a href="javascript:void(0)" title="Go back to support details search" (click)="back()">Back</a>
  </span>
</div>

