import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../models/user';
import { UserDetailMessageService } from '../../services/message.service';

@Component({
  selector: 'app-user-account-detail',
  templateUrl: './user-account-detail.component.html',
  styleUrls: ['./user-account-detail.component.scss']
})
export class UserAccountDetailComponent implements OnInit {

  @Input() user: User;

  @Input() itemIndex: number = 0;

  @Input() numberOfDuplicates: number = 0;

  get hideViewButton(): boolean {
    return this.numberOfDuplicates == 1;
  }

  constructor(private router: Router, private userDetailMessageService: UserDetailMessageService) {
    this.user = new User();
  }

  ngOnInit(): void {
    this.userDetailMessageService.setMessage(this.user);
  }

  view(): void {
    this.router.navigate(['dashboard']);
  }

}
