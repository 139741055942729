<div class="grid__cell--span-all mdc-typography--caption text--align-left text--weight-light mb-1 mt-3">
  <span>PORTFOLIO</span>
</div>
<div>
  <app-agreement-error [errors]="errorTypes"
   [agreementFailed]="agreementFailed"
   [certificateFaild]="certificateFaild"
   [agreementTypeNotFound]="agreementType.agreementTypeNotFound"
  *ngIf="showErrorTypes"></app-agreement-error>
  <app-health-care *ngIf="agreementType.showHealthCare" (healthErrorsEventEmitter)="agreementErrors($event)"></app-health-care>
  <app-momentum-insurance *ngIf="agreementType.showShortTermInsurance" (mstiErrorsEventEmitter)="agreementErrors($event)"></app-momentum-insurance>
  <app-life-insurance *ngIf="agreementType.showlifeInsurance" (lifeErrorsEventEmitter)="agreementErrors($event)"></app-life-insurance>
  <app-savings-and-investment *ngIf="agreementType.showSavingsAndInvestment" (sniErrorsEventEmitter)="agreementErrors($event)"></app-savings-and-investment>
  <app-wellness-and-rewards *ngIf="agreementType.showWellnesAndRewards" (rewardErrorsEventEmitter)="agreementErrors($event)"></app-wellness-and-rewards>
</div>
<app-loader *ngIf="isLoading"></app-loader>
