export class Currency {

  private _name: string;
  public get name(): string {
    return this._name;
  }

  private _symbol: string;
  public get symbol(): string {
    return this._symbol;
  }

  constructor(name: string, symbol: string) {
    this._name = name;
    this._symbol = symbol;
  }

  public static EMPTY = new Currency("", "");
}
