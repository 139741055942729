import { AdditionalHealthCover } from "./additional-health-cover";
import { Status } from "./api/status";

export class healthcareContract {

  public agreementSubtype: string;
  public name: string;
  public origin: string;
  public contractNumber: string;
  public membershipNumber: string;
  public description: string;
  public optionName: string;
  public availableBalance: number = 0;
  public status: string;
  public _status: Status;
  public additionalHealthCover: AdditionalHealthCover[] = [];

  constructor(agreementSubtype: string,name: string, origin: string, contractNumber: string, membershipNumber: string, description: string, optionName: string, status: string, _status: Status) {
    this.agreementSubtype = agreementSubtype;
    this.name = name;
    this.origin = origin;
    this.contractNumber = contractNumber;
    this.membershipNumber = membershipNumber;
    this.description = description;
    this.optionName = optionName;
    this.status = status
    this._status = _status;

  }

  public static EMPTY = new healthcareContract("", "", "", "", "", "", "", "", Status.EMPTY);

}
