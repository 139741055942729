import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ENDPOINT } from '../helpers/constants';
import { SupportReferenceHelper } from '../helpers/support-reference-helper';
import { CreateResponse } from '../models/api/create-response';
import { SupportReference } from '../models/support-reference';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class SupportReferenceService extends HttpService {
  private applicationName = "/client-web-support-portal-api/";

  constructor(http: HttpClient) {
    super(http);
    this.initialisePath()
  }

  private initialisePath(): void {
    let domain = this.getDomain();
    this.path = domain + this.applicationName;
    console.log("current path: " + this.path);
  }

  public create(supportReference: SupportReference): Observable<CreateResponse> {
    let request = SupportReferenceHelper.buildCreateRequest(supportReference);
    return super.post(ENDPOINT.SUPPORT_REFERENCE, request);
  }
}
