<div class="grid mat-grid">

  <div class="grid__inner page-section--first grid__inner--layout-c6c text--align-center">
      <div class="grid__cell text--align-center">
          <h2 class="mdc-typography--headline2">Support Details</h2>
          <span><p>To enable account search, enter your support reference number.</p></span>
      </div>
      <div class="grid__cell bg-white grid__plane--form-padding">
          <form  class="motion-form" #formDirective="ngForm" (ngSubmit)="submit()">
              <mat-form-field class="motion-form-field" >
                  <input matInput placeholder="Support reference number" [formControl]="supportReference.supportReferenceNumber" type="text"/>
                  <mat-error>{{supportReference.supportReferenceNumber.errors?.['message']}}</mat-error>
                </mat-form-field>
              <div class="motion-form-actions">
                  <button mat-raised-button color="accent" class="mat-button-primary motion-button--medium" type="submit">
                      Continue
                  </button>
              </div>
          </form>
      </div>
  </div>

</div>

<app-loader *ngIf="isLoading"></app-loader>
