import { FormControl } from "@angular/forms";
import { ValidateIdentificationType } from "../directives/validate-identification-type.directive";
import { ValidateIdentificationValue } from "../directives/validate-identification-value.directive";

export class ClientIdentification {

    public fieldKey: FormControl;
    public fieldValue: FormControl;

    constructor() {
        this.fieldKey = new FormControl('', ValidateIdentificationType);
        this.fieldValue = new FormControl('', ValidateIdentificationValue);
    }

    public isValid(): boolean {
        this.fieldKey.updateValueAndValidity();
        this.fieldValue.updateValueAndValidity();

        return this.fieldKey.valid &&  this.fieldValue.valid
    }

    public setValues( key: string, Value: string){
        this.fieldKey.setValue(key);
        this.fieldValue.setValue(Value);
    }


}
