export class AgreementTypes {

  showHealthCare: boolean = false;
  showlifeInsurance: boolean = false;
  showMomentumInsurance: boolean = false;
  showSavingsAndInvestment: boolean = false;
  showWellnesAndRewards: boolean = false;
  showShortTermInsurance: boolean = false;
  agreementTypeNotFound: boolean = true;

  constructor(showHealthCare: boolean, showlifeInsurance: boolean, showMomentumInsurance: boolean,showSavingsAndInvestment: boolean,
     showWellnesAndRewards: boolean, showShortTermInsurance: boolean,agreementTypeNotFound: boolean) {

    this.showHealthCare = showHealthCare;
    this.showlifeInsurance = showlifeInsurance;
    this.showMomentumInsurance = showMomentumInsurance;
    this.showWellnesAndRewards = showWellnesAndRewards;
    this.showSavingsAndInvestment = showSavingsAndInvestment;
    this.showShortTermInsurance = showShortTermInsurance;
    this.agreementTypeNotFound = agreementTypeNotFound;

  }

  public static EMPTY = new AgreementTypes(false, false, false, false, false, false, true);
}
