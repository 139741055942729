import { Validator } from '../directives/validator';

export class ProductType {

    private _name : string;
    public get name() : string {
        return this._name;
    }


    private _values : string[];
    public get values() : string[] {
        return this._values;
    }

    public constructor(name: string, values: string[]) {
        this._name = name;
        this._values = values;
    }

    public hasValue(value: string): boolean {
        for (let i=0;i<this.values.length;i++)
            if (Validator.isEqual(this.values[i], value))
                return true;

        return false;
    }
}
