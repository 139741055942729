import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, first, map, retry } from 'rxjs/operators';
import { ClientIdentificationTypeMapper } from '../helpers/client-identification-type-mapper';
import { APPLICATION_NAME, ENDPOINT } from '../helpers/constants';
import { ClientIdentificationRequest } from '../models/api/client-identification-request';
import { ClientIdentification } from '../models/client-identification';
import { ErrorWithCause } from '../models/error-with-cause';
import { User } from '../models/user';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class ClientIdentificationService extends HttpService {

  constructor(http: HttpClient) {
    super(http);
    this.initialisePath();
  }

  private initialisePath(): void {
    let domain = this.getDomain();
    this.path = domain + APPLICATION_NAME;
  }

  public getUserDetails(field: string, fieldValue: string): Observable<User[]> {

    const request = ClientIdentificationTypeMapper.buildCreateSearchRequest(field, fieldValue);
    const url: string = ENDPOINT.SEARCH_QUERY
    return super.getWithParam<User[]>(url, request).pipe(
      retry(2),
      first(),
      map((user: User[]) => {
        return user ? user : [];
      }),
      catchError((error: HttpErrorResponse) => {
        throw error;
      })
    );
  }

  public getQueryTypes(): Observable<ClientIdentificationRequest[]> {
    const url: string = ENDPOINT.QUERY_TYPES
    return super.get<ClientIdentificationRequest[]>(url).pipe(
      retry(2),
      first(),
      map((clientIdentification: ClientIdentificationRequest[]) => {
        return clientIdentification ? clientIdentification : [];
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of([]);
        } else {
          throw error;
        }
      })
    );
  }



}
