import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ENDPOINT, AGGREMENT_TYPE, APPLICATION_NAME } from '../helpers/constants';
import { HealthCareAgreement } from '../models/api/health-care-agreement';
import { LifeInsuranceAgreement } from '../models/api/life-insurance-agreement';
import { MomentumInsuranceAgreement } from '../models/api/momentum-insurance-agreement';
import { SavingsAndInvestmentAgreement } from '../models/api/savings-and-investment-agreement';
import { ShortTermInsuranceAgreement } from '../models/api/short-term-insurance-agreement';
import { WellnessAndRewardAgreement } from '../models/api/wellness-and-rewards-agreement';
import { HttpService } from './http.service';
import { catchError, first, map, mergeMap, retry } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AgreementService extends HttpService {

  constructor(http: HttpClient) {
    super(http);
    this.initialisePath()
  }

  private initialisePath(): void {
    let domain = this.getDomain();
    this.path = domain + APPLICATION_NAME;
    console.log("current path: " + this.path);
  }

  public getHealthCareAgreement(guid: string): Observable<HealthCareAgreement[]> {
    const url: string = ENDPOINT.AGREEMENT + guid + AGGREMENT_TYPE.HEALTH_AND_CARE
    return super.get<HealthCareAgreement[]>(url).pipe(
      retry(2),
      first(),
      map((agreement: HealthCareAgreement[]) => {
        return agreement ? agreement : [];
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of([]);
        } else {
          throw error;
        }
      })
    );
  }

  public getShortTermInsuranceAgreement(guid: string): Observable<ShortTermInsuranceAgreement[]> {
    const url: string = ENDPOINT.AGREEMENT + guid + AGGREMENT_TYPE.SHORT_TERM_INSURANCE
    return super.get<ShortTermInsuranceAgreement[]>(url).pipe(
      retry(2),
      first(),
      map((agreement: ShortTermInsuranceAgreement[]) => {
        return agreement ? agreement : [];
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of([]);
        } else {
          throw error;
        }
      })
    );
  }

  public getLongTermInsuranceAgreement(guid: string): Observable<LifeInsuranceAgreement[]> {
    const url: string = ENDPOINT.AGREEMENT + guid + AGGREMENT_TYPE.LIFE_INSURANCE;
    return super.get<LifeInsuranceAgreement[]>(url).pipe(
      retry(2),
      first(),
      map((agreement: LifeInsuranceAgreement[]) => {
        return agreement ? agreement : [];
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of([]);
        } else {
          throw error;
        }
      })
    );
  }

  public getMomentumInsuranceAgreement(guid: string): Observable<MomentumInsuranceAgreement[]> {
    const url: string = ENDPOINT.AGREEMENT + guid + AGGREMENT_TYPE.SHORT_TERM_INSURANCE;
    return super.get<MomentumInsuranceAgreement[]>(url).pipe(
      retry(2),
      first(),
      map((agreement: MomentumInsuranceAgreement[]) => {
        return agreement ? agreement : [];
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of([]);
        } else {
          throw error;
        }
      })
    );
  }

  public getSavingsAndInvestmentAgreement(guid: string): Observable<SavingsAndInvestmentAgreement[]> {
    const url: string = ENDPOINT.AGREEMENT + guid + AGGREMENT_TYPE.SAVINGS_AND_INVESTMENTS;
    return super.get<SavingsAndInvestmentAgreement[]>(url).pipe(
      retry(2),
      first(),
      map((agreement: SavingsAndInvestmentAgreement[]) => {
        return agreement ? agreement : [];
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of([]);
        } else {
          throw error;
        }
      })
    );
  }

  public getWellnessAndRewardsAgreement(guid: string): Observable<WellnessAndRewardAgreement[]> {
    const url: string = ENDPOINT.AGREEMENT + guid + AGGREMENT_TYPE.WELLNESS_AND_REWARDS;
    return super.get<WellnessAndRewardAgreement[]>(url).pipe(
      retry(2),
      first(),
      map((agreement: WellnessAndRewardAgreement[]) => {
        return agreement ? agreement : [];
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of([]);
        } else {
          throw error;
        }
      })
    );
  }
}
