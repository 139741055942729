import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
  issuer: environment.authO.issuer,
  tokenEndpoint: environment.authO.issuer + '/protocol/openid-connect/token',
  loginUrl: environment.authO.issuer + '/protocol/openid-connect/auth',
  logoutUrl:environment.authO.originUri + '/home',
  postLogoutRedirectUri: environment.authO.originUri+ '/home',
  redirectUri: environment.authO.originUri + '/support-details',
  dummyClientSecret: environment.authO.clientSecret,
  clientId: environment.authO.clientId,
  responseType: environment.authO.response_type,
  scope: environment.authO.scope,
  useSilentRefresh: false, // Needed for Code Flow to suggest using iframe-based refreshes
  silentRefreshTimeout: 0.000001,
  timeoutFactor: environment.authO.timeoutFactor,
  sessionChecksEnabled: false,
  disableAtHashCheck: false,
  showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
  clearHashAfterLogin: true, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
  nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
  strictDiscoveryDocumentValidation: false
};

