import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../models/user';

@Component({
  selector: 'app-profile-summary',
  templateUrl: './profile-summary.component.html',
  styleUrls: ['./profile-summary.component.scss']
})
export class ProileSummaryComponent implements OnInit {

  @Input() userProfile:User;

  constructor() {
    this.userProfile = new User;
  }

  ngOnInit(): void {
  }

}
