import { Validator } from "../directives/validator";
import { WellnessAndRewardAgreement } from "../models/api/wellness-and-rewards-agreement";
import { WellnessAndRewardContract } from "../models/wellness-and-rewards-contract";

export class WellnessAndRewardsHelper1 {

  public static getContrats(agreements: WellnessAndRewardAgreement[]): WellnessAndRewardContract {
    let contract = WellnessAndRewardContract.EMPTY;

    if (Validator.hasValue(agreements)) {
      agreements.forEach(agreement => {
         contract = this.getWellnessAndReward(agreement);
      });
    }

    return contract;
  }

  private static getWellnessAndReward(agreement: WellnessAndRewardAgreement): WellnessAndRewardContract {
    let contract = WellnessAndRewardContract.EMPTY;

    if (Validator.hasValue(agreement)) {
      let origin = agreement.origin;
      let contractAlpha = agreement.contractAlpha;
      let contractNumber = agreement.contractNumber;
      let status = agreement.status;
      let points = agreement.points;
      let pointsToNextTierStatus = agreement.pointsToNextTierStatus;
      let pointsToCurrentTierStatus = agreement.pointsToCurrentTierStatus;
      let daysToNextTier = agreement.daysToNextTier;
      let dateToNextTierStatus = agreement.dateToNextTierStatus;
      let dateToNextTierStatusReached = agreement.dateToNextTierStatusReached;
      let longMessage = agreement.longMessage;
      let shortMessage = agreement.shortMessage;

      contract = new WellnessAndRewardContract(origin, contractAlpha, contractNumber, status, points, pointsToNextTierStatus,
        pointsToCurrentTierStatus, daysToNextTier, dateToNextTierStatus, dateToNextTierStatusReached, longMessage, shortMessage);
    }
    return contract;
  }
}
