import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { User } from '../../models/user';
import { AgreementService } from '../../services/agreement.service';
import { UserDetailMessageService } from '../../services/message.service';
import { Clipboard } from "@angular/cdk/clipboard"
import { LifeInsuranceAgreement } from '../../models/api/life-insurance-agreement';
import { first } from 'rxjs';
import { LifeInsuranceAgreementHelper } from '../../helpers/life-insurance-helper';
import { LifeInsuranceContract } from '../../models/life-insurance-contract';
import { HttpErrorResponse } from '@angular/common/http';
import { Validator } from '../../directives/validator';
import { Utility } from '../../helpers/utility';
import { Status } from '../../models/api/status';
import { AgreementErrorType } from '../../models/agreement-error-type';
import { AGREEMENT } from '../../helpers/constants';

@Component({
  selector: 'app-life-insurance',
  templateUrl: './life-insurance.component.html',
  styleUrls: ['./life-insurance.component.scss']
})
export class LifeInsuranceComponent implements OnInit {

  public isLoading = true;
  private user: User;
  public contracts: LifeInsuranceContract[];
  @Output() lifeErrorsEventEmitter = new EventEmitter();
  public serviceFailure: boolean = false;


  constructor(private agreementService: AgreementService, private userDetailMessageService: UserDetailMessageService,
    private clipboard: Clipboard) {
    this.user = new User();
    this.contracts = [];
  }

  ngOnInit(): void {
    this.userDetailMessageService.currentMessage.subscribe(message => {
      this.user = (message) ? <User>message : new User();
    });
    this.userDetailMessageService.setMessage(this.user);

    this.getLifeInsuranceAgreement();
  }


  public getLifeInsuranceAgreement(): void {
    if (this.user) {
      this.isLoading = true;

      this.agreementService.getLongTermInsuranceAgreement(this.user.uniqueId).pipe(first()).subscribe({
        next: (response: LifeInsuranceAgreement[]) => {

          let agreements = (<any>response).LIFE_INSURANCE;
          this.contracts = LifeInsuranceAgreementHelper.getContracts(response);
          this.isLoading = false;
        },
        error: (error: Error) => {
          this.isLoading = false;
          this.serviceFailure = true
          console.log("Error loading life insurence agreement.", error.message);
          let errorType = new AgreementErrorType(error, AGREEMENT.ERROR.AGREEMENTS, AGREEMENT.TYPE.LONG_TERM_INSURANCE);
          this.lifeErrorsEventEmitter.emit(errorType);
          this.forceErrorOnContract();
        }
      });
    }

  }


  getCapitalizedFirstLetter(text: string): string {
    return Validator.getCapitalizedFirstLetterText(text);
  }

  public getStatus(status?: boolean): boolean {
    if (status == true || !this.serviceFailure)
      return true;
    return false;
  }

  private forceErrorOnContract() {
    if (this.contracts.length == 0) {
      let contract = LifeInsuranceContract.EMPTY;
      let status = Status.EMPTY;
      status = new Status(false, 0);
      contract._status = status;
      this.contracts.push(contract);
    }
  }

  copyPolicyNumber(policyNumber: string) {
    this.clipboard.copy(policyNumber);
  }
}
