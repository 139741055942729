import { Validator } from './validator';
import { Error } from '../models/error';
import { AbstractControl } from '@angular/forms';

export function ValidateNumber(control: AbstractControl): { [key: string]: any } | null {
  let value = control.value;

  if (!Validator.hasValue(value))
    return new Error('Please enter a number.');
  else if (!Validator.isaNumber(value))
    return new Error('Please enter a valid number.');
  else
    return null;

}
