<div class="motion-error card-panel card-panel--transitionfadein grid__inner mb-2" *ngIf="!agreementTypeNotFound">
  <div class="grid__cell grid__cell--span-all bg-white p-4 ">
    <div class="">
      <div class="card-panel__container text--align-left">
        <div class="card-panel__data-container mdc-typography--body2 d-flex">
          <div class="pr-3">
            <mat-icon class="error-label mdc-typography--headline3">
              cancel
            </mat-icon>
          </div>
          <div class="text--align-left error-label text--weight-semibold ">
              <span>{{agreementFailed}} agreements failed</span>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>



<div class="motion-error card-panel card-panel--transitionfadein grid__inner mb-2" *ngIf="agreementTypeNotFound">
  <div class="grid__cell grid__cell--span-all bg-white p-4">
    <div class="">
      <div class="card-panel__container text--align-left">
        <div class="card-panel__data-container mdc-typography--body2 d-flex">
          <div class="pr-3">
            <mat-icon class="error-label mdc-typography--headline3">
              cancel
            </mat-icon>
          </div>
          <div class="text--align-left error-label text--weight-semibold pt-1">
            <span class="mb-2">No Portfolio linked. Please check Rollback logs.</span>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
