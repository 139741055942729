import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { User } from '../models/user';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  protected messageSource = new BehaviorSubject<any>(null);
  public currentMessage = this.messageSource.asObservable();

  public setMessage(message: any): void {
    this.messageSource.next(message);
  }

  public clear(): void {
    this.messageSource.next(null);
  }
}

@Injectable({
  providedIn: 'root'
})
export class UserDetailMessageService extends MessageService {

  public override setMessage(user: User): void {
    super.setMessage(user);
  }

}

@Injectable({
  providedIn: 'root'
})
export class SupportReferenceMessageService extends MessageService {

  public override setMessage(supportReferenceValue: string): void {
    super.setMessage(supportReferenceValue);
  }

}
