import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { InterceptorSkipHeader } from '../services/http.service';
import { AuthService } from '../services/auth/auth-service.service';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.has(InterceptorSkipHeader)) {
      const headers = request.headers.delete(InterceptorSkipHeader);
      const clonedRequest = request.clone({ headers });

      return next.handle(clonedRequest);
    }
    else {
      const token = this.authService.accessToken;

      const clonedRequest = request.clone({
        headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
        })
      });

      return next.handle(clonedRequest);
    }
  }

}
export const authInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }];
