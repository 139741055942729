import { Validator } from "../directives/validator";
import { Amount } from "../models/amount";
import { Money } from "../models/api/money";
import { SavingsAndInvestmentAgreement } from "../models/api/savings-and-investment-agreement";
import { Currency } from "../models/currency";
import { SavingsAndInvestmentContract } from "../models/savings-and-investment-contract";
import { CURRENCIES } from "./constants";

export class SavingdAndInvestmentHelper {

  public static getContracts(agreements: SavingsAndInvestmentAgreement[]): SavingsAndInvestmentContract[] {
    let contracts: SavingsAndInvestmentContract[] = [];

    if (Validator.hasItems(agreements)) {

      agreements.forEach(agreement => {
        let contract = this.getContract(agreement);
        contracts.push(contract);
      });
    }
    return contracts;
  }

  private static getContract(agreement: SavingsAndInvestmentAgreement): SavingsAndInvestmentContract {
    let contract = SavingsAndInvestmentContract.EMPTY;

    if (Validator.hasValue(agreement)) {

      let origin = agreement.origin;
      let contractAlpha = agreement.contractAlpha;
      let contractNumber = agreement.contractNumber;
      let personNumber = agreement.personNumber;
      let name = agreement.description;
      let status = agreement.status;
      let _status = agreement._status;
      let businessUnit = agreement.businessUnit;
      let inceptionDate = agreement.inceptionDate;
      let acceptanceDate = agreement.acceptanceDate;
      let investmentValue = this.getAmount(agreement.investmentValue);
      let currentValue = this.getAmount(agreement.currentValue);

      contract = new SavingsAndInvestmentContract(origin, contractAlpha, contractNumber, personNumber, name, status, _status, businessUnit,
        inceptionDate, acceptanceDate, investmentValue, currentValue);
    }

    return contract;

  }

  private static getAmount(money: Money): Amount {
    if (Validator.hasValue(money) && Validator.hasValue(money.value) && Validator.hasValue(money.currency))
      return new Amount(money.value, <Currency>CURRENCIES.get(money.currency));

    return Amount.EMPTY;
  }
}
