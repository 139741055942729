<div class="grid mat-grid">
  <div class="grid__inner page-section grid__inner--layout-c6c text--align-center">
      <div class="grid__cell">
          <p class="mdc-typography--body2 text--align-center mb-4">Support reference: <b>{{supportReference}}</b></p>
          <h2 class="mdc-typography--headline2 text--align-center">Support details</h2>
          <p class="mdc-typography--body2 text--align-center mb-4">Select a search option</p>
          <div class="bg-white grid__plane--form-padding">
              <form class="motion-form" #formDirective="ngForm" (ngSubmit)="submit()">
                  <mat-form-field class="motion-form-field">
                      <mat-select placeholder="Search for" panelClass="bg-white mat-elevation-z4"
                          [formControl]="clientIdentification.fieldKey" (selectionChange)="clear()">
                        <mat-option *ngFor="let searchType of clientIdentificationOptions" [value]="searchType">
                          {{searchType.queryTypeDisplayValue}}
                        </mat-option>
                      </mat-select>
                      <mat-error>{{clientIdentification.fieldKey.errors?.['message']}}</mat-error>
                  </mat-form-field>

                  <mat-form-field class="motion-form-field">
                      <input matInput placeholder="Enter {{(clientIdentification.fieldKey.value.queryTypeDisplayValue ? clientIdentification.fieldKey.value.queryTypeDisplayValue : 'identity number')| lowercase}}" [formControl]="clientIdentification.fieldValue"/>
                      <mat-error>{{clientIdentification.fieldValue.errors?.['message']}} {{(clientIdentification.fieldKey.value.queryTypeDisplayValue ? clientIdentification.fieldKey.value.queryTypeDisplayValue : 'identity number')| lowercase}}.</mat-error>
                  </mat-form-field>

                  <div class="motion-form-actions">
                      <button [disabled]="isLoading" mat-raised-button color="accent" class="mat-button-primary motion-button--medium" type="submit">
                          Continue
                      </button>
                  </div>
              </form>
          </div>
      </div>
      <div class="grid__cell mt-4" *ngIf="noProfileFound">
          No profile found. Please try again.
      </div>
      <div class="grid__cell mt-4" *ngIf="userDetailsResult">
          <p class="mdc-typography--body2 text--align-center mb-4">Select profile</p>
          <div class="card-panel grid__inner mb-2">
              <div class="grid__cell grid__cell--span-all">
                  <div class="mdc-card" style="margin-bottom: 5px;" *ngFor="let userDetail of users; let i = index">
                      <app-user-account-detail [user]="userDetail" [itemIndex]="i" [numberOfDuplicates]="users.length"></app-user-account-detail>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="grid__cell text--align-center">
      <span>
        <a href="javascript:void(0)" title="Go back to support details search" (click)="back()">Back</a>
      </span>
  </div>
</div>

<!-- <app-loader *ngIf="isLoading"></app-loader> -->
