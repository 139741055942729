export class SaIdValidator {

  public static hasValidChecksum(value: string) {
    let controlDigit = this.getControlDigit(value);
    let validChecksum = (controlDigit % 10 == 0);
    return validChecksum;
  }

  public static hasValidDate(value: string): boolean {
    let validDate = false;
    let date = this.getDate(value);

    if (date != null) {
      let currentDate = new Date();
      validDate = (date <= currentDate);
    }

    return validDate;
  }

  private static getControlDigit(value: string): number {
    let total = 0;
    let count = 0;
    for (let i = 0; i < value.length; i++) {
      let multiplier = count % 2 + 1;
      count++;
      let temp = multiplier * parseInt(value.charAt(i));
      temp = Math.floor(temp / 10) + (temp % 10);
      total += temp;
    }

    total = (total * 9) % 10;

    let controlDigit = (total % 10);

    return controlDigit;
  }

  private static getDate(value: string): Date {
    let dayValueText = value.substring(4, 6);
    let monthValueText = value.substring(2, 4);
    let yearValueText = value.substring(0, 2);

    let dateText = dayValueText + " " + this.getMonth(monthValueText) + " " + this.getYear(yearValueText);

    if (Date.parse(dateText))
      return new Date(dateText);
    else
      return null as any;
  }

  private static getYear(yearValueText: string): number {
    let year = parseInt(yearValueText) + 1900;
    let currentYear = (new Date()).getFullYear();

    if ((currentYear - year) > 100)
      year = year + 100;

    return year;
  }

  private static getMonth(monthValueText: string): string {
    let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let month = months[parseInt(monthValueText) - 1];

    return month;
  }
}
