<div class="" *ngIf="userAccount">
  <div class="card-panel__heading-container mdc-typography--body2 mb-2 text--align-center
              text--align-left-desktop">

    <div class="">
      <h3 class="mdc-card__headline mdc-typography--headline5">
        {{userAccount.firstname}} {{userAccount.surname}}<span class="text--weight-light"></span>
      </h3>
    </div>
  </div>
  <div class="card-panel__data-container mdc-typography--body2
    text--dark-high-emphasis text--align-left bt-1-motion-grey--light mt-4">

    <div class="card-panel__data-item grid__inner grid__inner--layout-66-44-22 d-flex pt-1 pb-1 bb-1-motion-grey--light">
      <div class=" grid__cell mb-0 text--align-left-tablet grid__cell--align-middle mat-cell--width-20">
        Username
      </div>
      <div class="grid__cell mb-0 text--align-right-tablet text--align-right text--weight-semibold mat-cell--width-80 text--breakword">
        {{userAccount.username}}
        <a href="javascript:void(0)"(click)="copyUsername()" aria-label="Copy id number">
          <mat-icon class="text--dark-medium-emphasis d-valign-middle motion-icon--inline">content_copy</mat-icon>
        </a>
      </div>
    </div>

    <div class="card-panel__data-item grid__inner d-flex grid__inner--layout-66-44-22 pt-1 pb-1 bb-1-motion-grey--light" *ngIf="userAccount.idNumber">
      <div class=" grid__cell mb-0 text--align-left-tablet grid__cell--align-middle mat-cell--width-45">
        SA ID number
      </div>
      <div class="grid__cell mb-0 text--align-right-tablet text--align-right text--weight-semibold text--nowrap mat-cell--width-55">
        {{userAccount.idNumber}}
        <a href="javascript:void(0)" (click)="copyIdNumber()" aria-label="Copy id number">
          <mat-icon class="text--dark-medium-emphasis d-valign-middle motion-icon--inline">content_copy</mat-icon>
        </a>
      </div>
    </div>

    <div class="card-panel__data-item grid__inner grid__inner--layout-66-44-22 d-flex pt-1 pb-1 bb-1-motion-grey--light" *ngIf="userAccount.passportNumber">
      <div class=" grid__cell mb-0 text--align-left-tablet grid__cell--align-middle mat-cell--width-45">
        Passport number
      </div>
      <div class="grid__cell mb-0 text--align-right-tablet text--align-right text--weight-semibold mat-cell--width-55">
        {{userAccount.passportNumber}}
        <a href="javascript:void(0)" aria-label="Copy passport number">
          <mat-icon class="text--dark-medium-emphasis d-valign-middle motion-icon--inline">content_copy</mat-icon>
        </a>
      </div>
    </div>

    <div class="card-panel__data-item grid__inner d-flex grid__inner--layout-66-44-22 d-flex pt-1 pb-1 bb-1-motion-grey--light">
      <div class=" grid__cell mb-0 text--align-left-tablet grid__cell--align-middle mat-cell--width-50">
        Status
      </div>
      <div class="grid__cell mb-0 text--align-right-tablet text--align-right text--weight-semibold mat-cell--width-50">
        {{getAccountStatus(userAccount.accountStatus)}}
      </div>
    </div>

    <div class="card-panel__data-item grid__inner grid__inner--layout-66-44-22 d-flex pt-1 pb-1 bb-1-motion-grey--light">
      <div class=" grid__cell mb-0 text--align-left-tablet grid__cell--align-middle mat-cell--width-50">
        Age
      </div>
      <div class="grid__cell mb-0 text--align-right-tablet text--align-right text--weight-semibold mat-cell--width-50">
        {{age}}
      </div>
    </div>

    <div class="card-panel__data-item grid__inner grid__inner--layout-66-44-22 d-flex pt-1 pb-1 bb-1-motion-grey--light">
      <div class=" grid__cell mb-0 text--align-left-tablet grid__cell--align-middle mat-cell--width-50">
        Cellphone number
      </div>
      <div class="grid__cell mb-0 text--align-right-tablet text--align-right text--weight-semibold mat-cell--width-50">
        {{userAccount.cellularNumber}}
      </div>
    </div>

    <div class="card-panel__data-item grid__inner grid__inner--layout-66-44-22 d-flex pt-1 pb-1 bb-1-motion-grey--light" *ngIf="userAccount.emailAddress">
      <div class=" grid__cell mb-0 text--align-left-tablet grid__cell--align-middle mat-cell--width-40">
        Email address
      </div>
      <div class="grid__cell mb-0 text--align-right-tablet text--align-right text--weight-semibold mat-cell--width-60 text--breakword" >
        <span>{{userAccount.emailAddress}}</span>
      </div>
    </div>

    <div class="card-panel__data-item grid__inner grid__inner--layout-66-44-22 d-flex pt-1 pb-1 bb-1-motion-grey--light">
      <div class=" grid__cell mb-0 text--align-left-tablet grid__cell--align-middle mat-cell--width-25">
        Last login
      </div>
      <div class="grid__cell mb-0 text--align-right-tablet text--align-right text--weight-semibold mat-cell--width-75 text--breakword">
        {{ userAccount.dateLastLogin | date: 'd MMM yyyy (HH:mm)'}}
      </div>
    </div>

  </div>
