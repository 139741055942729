import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs';

import { SupportReference } from '../../models/support-reference';
import { SupportReferenceMessageService } from '../../services/message.service';
import { SupportReferenceService } from '../../services/support-reference.service';



@Component({
  selector: 'app-support-details',
  templateUrl: './support-details.component.html',
  styleUrls: ['./support-details.component.scss']
})
export class SupportDetailsComponent implements OnInit {

  public isLoading: boolean = false;

  supportReference: SupportReference;

  constructor(private router: Router, private supportReferenceService: SupportReferenceService,
    private supportReferenceNumber: SupportReferenceMessageService) {
    this.supportReference = new SupportReference();
  }


  ngOnInit(): void {

  }

  public submit(): void {
    if (this.supportReference.isValid()) {

      this.isLoading = true;
      this.supportReferenceService.create(this.supportReference).pipe(first()).subscribe({
        next: (response) => {

          this.isLoading = false;
          console.log("response ---> ",response)
          this.supportReferenceNumber.setMessage(this.supportReference.supportReferenceNumber.value);
          this.router.navigate(['search-account']);

        }, error: (error: HttpErrorResponse) => {

          this.isLoading = false;
          console.log('Failed to save support reference number', error.message);

        }
      })

    }
  }
}
