<div id="profile" class="card-panel__body">

  <div class="grid__cell--span-all mdc-typography--caption text--align-left text--weight-light mb-1 mt-3">
    PROFILE DETAILS
  </div>

  <div class="card-panel grid__inner card-panel--transitionfadein" id="360-portfolio-card_profile">
    <div class="grid__cell grid__cell--span-all">
      <app-user-profile [userAccount]="userProfile"></app-user-profile>
    </div>
  </div>
</div>
