import { Validator } from "../directives/validator";
import { AdditionalHealthCover } from "../models/additional-health-cover";
import { HealthCareAgreement } from "../models/api/health-care-agreement";
import { healthcareContract } from "../models/health-care-contract";
import { HEALTH_AGREEMENT } from "./constants";

export class HealthcareHelper {

  public static getContracts(agreements: HealthCareAgreement[]): healthcareContract {

    let healthcare = healthcareContract.EMPTY;

    if (Validator.hasItems(agreements)) {
      agreements.forEach(agreement => {

        if (agreement.agreementSubtype == HEALTH_AGREEMENT.MEDICAL_AID || agreement.agreementSubtype == HEALTH_AGREEMENT.HEALTH_4_Me) {

          healthcare = this.getContract(agreement);

        } else if (agreement.agreementSubtype === HEALTH_AGREEMENT.HEALTH_SAVER || agreement.agreementSubtype === HEALTH_AGREEMENT.GAP_COVER) {
          let additionalCover = this.getAdditionalHealthCover(agreement);
          healthcare.additionalHealthCover.push(additionalCover);
        }
      })
    }

    return healthcare;

  }

  private static getContract(agreement: HealthCareAgreement): healthcareContract {
    let healthcare = healthcareContract.EMPTY;

    if (Validator.hasValue(agreement)) {
      let agreementSubtype =agreement.agreementSubtype
      let name = (agreementSubtype == HEALTH_AGREEMENT.MEDICAL_AID) ? 'Healthcare': 'Health4Me'
      let contractNumber = agreement.contractNumber
      let description = agreement.description
      let membershipNumber = agreement.membershipNumber
      let optionName = agreement.optionName
      let origin = agreement.origin
      let status = agreement.status
      let _status = agreement._status;

      healthcare = new healthcareContract(agreementSubtype,name, origin, contractNumber, membershipNumber, description, optionName, status, _status);
    }

    return healthcare;
  }

  private static getAdditionalHealthCover(agreement: HealthCareAgreement): AdditionalHealthCover {
    let additionalCover = AdditionalHealthCover.EMPTY;

    if (Validator.hasValue(agreement)) {
      let coverName = agreement.agreementSubtype
      let availableBalance = agreement.availableSavings;

      additionalCover = new AdditionalHealthCover(coverName, availableBalance);
    }

    return additionalCover;
  }

}
