import { DEFAULT } from "./constants";
const portalOrigin = window.location.origin;

export class Utility {

  private static currentPage = (<any>window);

  public static ScrollPageToTop(): void {
    window.scrollTo(0, 0);
  }

  public static ScrollToElement(elementName: string): void {
    const id = elementName;
    const yOffset = DEFAULT.OFFSET_VALUE;
    const element = document.getElementById(id);
    const y = element!.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  }

  public static GetDomain(): string {
    return window.location.origin;
  }


  static redirectToRefPage() {
    const url = portalOrigin + '/support-details';
    return url;
  }

}
