import { Component, Input, OnInit } from '@angular/core';
import { User } from '../../models/user';
import { Clipboard } from "@angular/cdk/clipboard"

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  @Input() userAccount: User;
  public age: number;
  public get idNumber(){
    return
  }

  constructor(private clipboard: Clipboard) {
    this.userAccount = new User()
    this.age = 0;
  }

  ngOnInit(): void {

    if (this.userAccount){
      this.age =  this.getAge(this.userAccount.birthDate);
    }
  }

  getAge(dateofBirth: string): number {
    const today = new Date();
    let birthDate = new Date(dateofBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
  }

  public getAccountStatus(accountStatus: string): string {
    if (accountStatus == "ACCOUNT_ACTIVE")
      return "Active";
    if (accountStatus == "ACCOUNT_INACTIVE")
      return "Inactive"
    else
      return  "Locked";
  }

  copyUsername(){
    let username = this.userAccount.username;
    this.clipboard.copy(username);
  }

  copyEmail(){
    let emailAddress = this.userAccount.emailAddress;
    this.clipboard.copy(emailAddress);
  }

  copyIdNumber(){
    let idNumber = this.userAccount.idNumber;
    this.clipboard.copy(idNumber);
  }

}
